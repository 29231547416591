import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import LoadingInline from './LoadingInline'
import badge10 from '../images/badges/badge10.svg'
import badge50 from '../images/badges/badge50.svg'
import badge100 from '../images/badges/badge100.svg'
import creator from '../images/badges/creator.svg'
import tweetActivity from '../images/badges/tweetActivity.svg'
import followerGrowth from '../images/badges/followerGrowth.svg'
import noMissedDays from '../images/badges/noMissedDays.svg'
import badgeChallengeInviter from '../images/badges/challengeInviter.svg'
import badgeTweepWarsInviter from '../images/badges/tweepWarsInviter.svg'
import badgeInvitee from '../images/badges/invitee.svg'
import './Offcanvas.css'

import { getBadges, getStreaks } from '../actions/stats'

import ReactTooltip from 'react-tooltip'

const OffcanvasPrivate = () => {
    const [auth, day, stats] = useSelector(state => [state.auth, state.day, state.stats], shallowEqual)
  
    const location = useLocation()
    const dispatch = useDispatch()
  
    const determineShow = (auth, pathname) => {
      return !auth || pathname.match('/day') || pathname === '/' ? false : true
    }
    const show = determineShow(auth, location.pathname)
  
    const checkBadges = (badge) => {
      if (stats && stats['badges']) {
        return stats['badges'].indexOf(badge) > -1
      } else {
        return false
      }
    }
  
    const checkLongestStreak = () => {
      if (stats && stats['streaks']) {
        return stats['streaks']['longestStreak']
      } else {
        return <LoadingInline/>
      }
    }
  
    const checkCurrentStreak = () => {
      if (stats && stats['streaks']) {
        return stats['streaks']['currentStreak']
      } else {
        return <LoadingInline/>
      }
    }
  
    const challengeCreator = checkBadges('creator')
    const challengeInviter = checkBadges('challengeInviter')
    const tweepWarsInviter = checkBadges('tweepWarsInviter')
    const invitee = checkBadges('invitee')
    const tweetActivityWin = checkBadges('tweetActivity')
    const noMissedDaysWin = checkBadges('noMissedDays')
    const followerGrowthWin = checkBadges('followerGrowth')
    const longestStreak = checkLongestStreak()
    const currentStreak = checkCurrentStreak()
  
    useEffect(() => {
      if (!stats['streaks']) {
        dispatch(getStreaks())
      }
    })
  
    useEffect(() => {
      if (!stats['badges']) {
        dispatch(getBadges())
      }
    })
  
    return (
      <>
        <div className={`d-none d-xl-block offcanvas offcanvas-end  ${show ? 'show' : ''}`} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
          <div className="container float-start">
            <div className="offcanvas-header">
              <nav className="navbar navbar-expand navbar-light">
                <ul className="navbar-nav float-end">
                  <li className="nav-item" key='challenges'><Link className="nav-link" to="/challenges">Challenges</Link></li>
                  <li className="nav-item" key='statistics'><Link className="nav-link" to={`/statistics/${day.toFormat('yyyy/MM/dd')}`}>Statistics</Link></li>
                  <li className="nav-item" key='settings'><Link className="nav-link" to="/settings">Settings</Link></li>                
                  <li className="nav-item" key='logout'><a className="nav-link" href="/api/logout">Logout</a></li>
                </ul>
              </nav>
              <>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </>
            </div>
            <div className="offcanvas-body">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      Streaks
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div className="accordion-body">
                      <p><strong>Current streak: {currentStreak ? currentStreak : 0}</strong></p>
                      <p><strong>Longest streak: {longestStreak ? longestStreak : 0}</strong></p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      Badges
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div className="accordion-body">
                      <img id="creator" data-tip="challenge creator" className={`img-fluid me-2 ${!challengeCreator ? 'opacity-25' : ''}`} src={creator} alt="challenge creator Badge"/>
                      <img id="badge10" data-tip="10 day streak" className={`img-fluid me-2 ${longestStreak < 10 || isNaN(longestStreak) ? 'opacity-25' : ''}`} src={badge10} alt="10 Day Streak Badge"/>
                      <img id="badge50" data-tip="50 day streak" className={`img-fluid me-2 ${longestStreak < 50 || isNaN(longestStreak) ? 'opacity-25' : ''}`} src={badge50} alt="50 Day Streak Badge"/>
                      <img id="badge100" data-tip="100 day streak" className={`img-fluid me-2 ${longestStreak < 100 || isNaN(longestStreak) ? 'opacity-25' : ''}`} src={badge100} alt="100 Day Streak Badge"/>
                      <img id="tweetActivity" data-tip="tweet challenge won" alt="tweet challenge won" className={`img-fluid me-2 ${!tweetActivityWin ? 'opacity-25' : ''}`} src={tweetActivity}/>
                      <img id="followerGrowth" data-tip="follower challenge won" alt="follower challenge won" className={`img-fluid me-2 ${!followerGrowthWin ? 'opacity-25' : ''}`} src={followerGrowth}/>
                      <img id="noMissedDays" data-tip="no missed days in a month" alt="no missed days in a month" className={`img-fluid me-2 ${!noMissedDaysWin ? 'opacity-25' : ''}`} src={noMissedDays}/>
                      <img id="challengeInviter" data-tip="challenge inviter" alt="challenge inviter" className={`img-fluid me-2 ${!challengeInviter ? 'opacity-25' : ''}`} src={badgeChallengeInviter}/>
                      <img id="tweepWarsInviter" data-tip="TweepWars inviter" alt="TweepWars inviter" className={`img-fluid me-2 ${!tweepWarsInviter ? 'opacity-25' : ''}`} src={badgeTweepWarsInviter}/>
                      <img id="invitee" data-tip="challenge invitee" alt="challenge invitee" className={`img-fluid me-2 ${!invitee ? 'opacity-25' : ''}`} src={badgeInvitee}/>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      Summary
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div className="accordion-body">
                      <p><strong>Challenges started: </strong></p>
                      <p><strong>Challenges completed: </strong></p>
                      <p><strong>Average Daily Tweets: </strong> <button className="btn btn-outline-dark btn-sm text-nowrap">toggle</button></p>
                      <p><strong>Followers gained: </strong></p>
                      <p><strong>Followers lost: </strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip />
        </div>
      </>
    )
  }

export default OffcanvasPrivate