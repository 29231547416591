import { DateTime } from 'luxon'

const statsReducerDefaultState = {
  dateRange: {
    startDate: DateTime.utc().startOf('month'),
    endDate: DateTime.utc()
  }
}

const statsReducer = (state = statsReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOAD_STATS':
      return {
        ...state,
        ...action.stats
      }
    default:
      return state
  }
}

export default statsReducer