import { challengeDraftOptions } from "../options/challengeDraftOptions"

const challengesDraftReducer = (state = challengeDraftOptions, action) => {
  switch (action.type) {
    case 'INITIALIZE_DRAFT':
      return action.challengeDraft
    case 'SET_DURATION':
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
        timeZone: action.timeZone
      }
    case 'SET_MIN_TWEETS':
      return {
        ...state,
        minTweets: action.minTweets
      }
    case 'SET_STAKE_PER_TWEET':
      return {
        ...state,
        stakePerTweet: action.stakePerTweet
      }
    case 'SET_CREATOR':
      return {
        ...state,
        creator: action.creator
      }
    case 'SET_INVITEE':
      return {
        ...state,
        invitee: action.invitee
      }
    default:
      return state
  }
}

export default challengesDraftReducer