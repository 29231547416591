import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Offcanvas.css'

import ReactTooltip from 'react-tooltip'

const OffcanvasPublic = () => {
    const location = useLocation()
    const determineShow = (pathname) => {
      return pathname === '/' ? false : true
    }
    const show = determineShow(location.pathname)
    const signedUp = 0
    const premiumUsers = 0
    return (
      <>
        <div className={`d-none d-xl-block offcanvas offcanvas-end  ${show ? 'show' : ''}`} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel">
          <div className="container float-start">
            <div className="offcanvas-header">
              <nav className="navbar navbar-expand navbar-light">
                <ul className="navbar-nav float-end">
                  <li className="nav-item" key='settings'><Link className="nav-link" to="/settings">Settings</Link></li>                
                  <li className="nav-item" key='logout'><a className="nav-link" href="/api/logout">Logout</a></li>
                </ul>
              </nav>
              <>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </>
            </div>
            <div className="offcanvas-body">
              <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      Site Statistics
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                    <div className="accordion-body">
                      <p><strong>Users signed up: {signedUp}</strong></p>
                      <p><strong>Premium users: {premiumUsers}</strong></p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      FAQ
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                    <div className="accordion-body">
                      <p>How can I support you?</p>
                      <p>How does TweepWars work?</p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                      Socials
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                    <div className="accordion-body">
                      <p><strong>Twitter</strong></p>
                      <p><strong>Discord </strong></p>
                      <p><strong>Telegram: </strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ReactTooltip />
        </div>
      </>
    )
  }

export default OffcanvasPublic