import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { getUser } from '../actions/index'
import { getRecentSnapshots } from '../actions/snapshots'
import './Home.css'

const Home = () => {
  const [auth, snapshots] = useSelector(state => [state.auth, state.snapshots], shallowEqual)
  const [snapshotsCollected] = useState(snapshots.length > 0)
  const dispatch = useDispatch()

  // Get user from database
  useEffect(() => {
    if (auth === 'ready')
    dispatch(getUser())
  }, [auth, dispatch])

  // Get snapshots from database
  useEffect(() => {
    if (auth && auth !== 'ready' && auth !== 'pending') {
      if (!snapshotsCollected) {
        dispatch(getRecentSnapshots())
      }
    }
  }, [snapshotsCollected, auth, dispatch])

  const renderContent = () => {
    switch (auth) {
      case null:
        return
      case false:
        return <a className="btn btn-dark btn-lg" href="/auth/twitter">Connect Twitter with one click</a>
      default:
        return
    }
  }

  return (
    <div id="outer">
      <div id="inner" className="text-center">
        <h1 className="display-1">TweepWars</h1>
        <h1>Home of Competitive Tweeting</h1>
        {renderContent()}
      </div>
    </div>
  )
}

export default Home