import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DateTime } from 'luxon'

import { setDateRange, getFollowerDelta } from '../actions/stats'

const DateInput = (props) => {
  const type = props.type
  const stats = useSelector(state => state.stats)
  const dateRange = stats.dateRange
  const dispatch = useDispatch()

  console.log(stats.dateRange)

  const determineDate = (type, dateRange) => {
    switch (type) {
      case 'start':
        return dateRange ? dateRange.startDate.toFormat('yyyy-MM-dd') : DateTime.utc().startOf('month').toFormat('yyyy-MM-dd')
      case 'end':
        return dateRange ? dateRange.endDate.toFormat('yyyy-MM-dd') : DateTime.utc().toFormat('yyyy-MM-dd')
      default:
        return DateTime.utc().toFormat('yyyy-MM-dd')
    }
  }

  const determineValidInput = (type, dateRange) => {
    console.log('date range', dateRange)
    if (dateRange) {
      switch (type) {
        case 'start':
          if (dateRange.startDate <= dateRange.endDate && dateRange.startDate <= DateTime.utc().endOf('day')) {
            return {
              valid: true,
              errorMessage: null
            }
          } else if (dateRange.startDate <= dateRange.endDate) {
            return {
              valid: false,
              errorMessage: 'start date cannot be in the future'
            }
          }  else {
            return {
              valid: false,
              errorMessage: 'start date cannot be later than end date'
            }
          }
        case 'end':
          if (dateRange.endDate <= DateTime.utc().endOf('day')) {
            return {
              valid: true,
              errorMessage: null
            }
          } else {
            return {
              valid: false,
              errorMessage: 'end date cannot be in the future'
            }
          }
        default:
          return {
            valid: false,
            errorMessage: 'unknown error'
          }
      }
    }
  }

  const validityCheck =  determineValidInput(type, dateRange) ? determineValidInput(type, dateRange) : {valid: false, errorMessage: ''}

  const placeholder = determineDate(type, dateRange)

  const setDate = (e) => {
    switch (type) {
      case 'start':
        const startDate = DateTime.fromISO(e.target.value, { zone: 'utc' })
        if (startDate <= DateTime.utc().endOf('day') && startDate <= dateRange.endDate) {
          dispatch(setDateRange(startDate, dateRange.endDate))
          dispatch(getFollowerDelta())

        }
        return
      case 'end':
        const endDate = DateTime.fromISO(e.target.value, { zone: 'utc' })
        if (endDate <= DateTime.utc().endOf('day') && dateRange.startDate <= endDate) {
          dispatch(setDateRange(dateRange.startDate, endDate))
          dispatch(getFollowerDelta())
        }
        return
      default:
        return
    }
  }

  console.log('placeholder', placeholder)
  console.log('validity check', validityCheck)

  return (
    <>
      <div id="inputContainer" className="input-group has-validation">
        <div id="formContainer" className="form-floating">
            <input
              className={`form-control text-center ${validityCheck.valid ? '' : 'is-invalid'}`}
              id="floatingInputDate"
              type="date"
              value={placeholder}
              onChange={setDate}
              required
            />
            <div id="floatingInputDateFeedback" className="invalid-feedback">{validityCheck.errorMessage}</div>
          <label className="form-control-placeholder" htmlFor="floatingInputDate">
            {type}
          </label>
        </div>
      </div>
    </>
  )
}

export default DateInput