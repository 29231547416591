const Terms = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>
        Terms and Conditions
      </h1>
      <p>Last Updated: October 17, 2022</p>
      <p>Thank you for using TweepWars, a goal setting and analytics web application.</p>
      <p>The TweepWars web application (hereafter, “App”) is an entity built and operated by Conrad (hereafter, “I”), domiciled in Zurich, Switzerland.</p>
      <p>Any interaction between users (hereafter, “You”, “Your”) and the App is subject to these Terms of Use (hereafter, “Terms”).</p>
      <p></p>
      <h6>Purpose of using the App</h6>
      You agree to use the App because You have made a commitment to improving your Twitter presence. By using the App, You agree to declare and quantify your goals by entering challenges. You agree to make an effort to successfully complete all challenges that you enter.
      <p></p>
      <h6>Eligibility</h6>
      I may limit Your access to the App or delete any data associated with your account without prior notice without liability to you.
      <br/>
      <p></p>
      <h6>Disclaimers</h6>
      I am not responsible or liable for content You create on Twitter that ends up displayed on the App.
      <p>The App may be temporarily or permanently unavailable for any reason. I assume no responsibility to keep the App accessible.</p>

    </div>
  )
}

export default Terms