import React, { useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { inviteUser } from '../actions/challenges'
import { setInvitee } from '../actions/challengeDraft'
import { getBadges } from '../actions/stats'
import { getUserSnapshotByDay } from '../actions/snapshots'
import { getUserFromScreenName } from '../selectors/users'
import FollowerGainInput from './FollowerGainInput'
import FollowerTarget from './FollowerTarget'
import ResponseModal from './ResponseModal'
import InviteAttempt from './InviteAttempt'
import LoadingPage from './LoadingPage'

import './ChallengeInviteForm.css'

const renderFollowerGrowthPrompt = (invitee) => {
  if (invitee.twitterId) {
    return (
      <>: <FollowerTarget twitterId={invitee.twitterId} date={DateTime.utc()} fixed={invitee.fixed} absoluteGain={invitee.absoluteGain} percentGain={invitee.percentGain}/></>
    )
  } else {
    return (<></>)
  }
}

const renderInvitePrompt = (invitee, nextMonthChallenge) => {
  const screenName = (invitee) => (invitee.screenName !== '' ? invitee.screenName : 'user')
  const challengeMonth = (nextMonthChallenge) => nextMonthChallenge.startDate.toFormat('MMMM')
  return (
    <p className="mt-5">
      Invite {screenName(invitee)} to your {challengeMonth(nextMonthChallenge)} challenge{renderFollowerGrowthPrompt(invitee)}
    </p>
  )
}

const ChallengeInviteForm = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [challengeDraft, users] = useSelector(state => [state.challengeDraft, state.users], shallowEqual)
  const [screenName, setScreenName] = useState('')
  const [validUser, setValidUser] = useState(false)
  const [responseModalText, setResponseModalText] = useState('')
  
  const updateInvitee = (e) => {
    const inviteeName = e.target.value
    const user = getUserFromScreenName(users, inviteeName)
    if (user) {
      setScreenName(user.screenName)
      setValidUser(true)
      dispatch(setInvitee({
        ...challengeDraft.invitee,
        twitterId: user.twitterId, 
        screenName: user.screenName
      }))
      dispatch(getUserSnapshotByDay(user.twitterId, DateTime.utc()))
      // setResponseModalText for existing users
      if (props.nextMonthChallenge.participants.map(participant => participant.twitterId).includes(user.twitterId)) {
        setResponseModalText(`${user.screenName} is already a confirmed participant`)
      } else if (props.nextMonthChallenge.invites.some(invite => user.twitterId === invite.invitee)) {
        if (props.nextMonthChallenge.invites.some(invite => user.twitterId === invite.invitee && invite.accepted === false)) {
          setResponseModalText(`${user.screenName} has previously declined an invite to this challenge`)
        } else {
          setResponseModalText(`${user.screenName} is already invited`)
        }
      } else {
        setResponseModalText('')
      }
    } else {
      // setResponseModalText when user does not exist
      setResponseModalText(`${inviteeName} has not registered an account with TweepWars yet.`)
      setScreenName(inviteeName)
      setValidUser(false)
      dispatch(setInvitee({
        ...challengeDraft.invitee,
        twitterId: '', 
        screenName: ''
      }))
    }
  }

  const triggerInviteUser = (e) => {
    e.preventDefault()
    if (users.some(user => user.twitterId === challengeDraft.invitee.twitterId) && !props.nextMonthChallenge.participants.map(participant => participant.twitterId).includes(challengeDraft.invitee.twitterId) && !props.nextMonthChallenge.invites.some((invite) => challengeDraft.invitee.twitterId === invite.invitee)) {
      // user exists and is neither already invited or registered as a participant
      dispatch(inviteUser(props.nextMonthChallenge, challengeDraft.invitee.twitterId))
      dispatch(getBadges())
      dispatch(setInvitee({
        ...challengeDraft.invitee,
        twitterId: '', 
        screenName: ''
      }))
      setScreenName('')
      setResponseModalText('')
      setValidUser(false)
    }
  }

  if (challengeDraft) {
    return (
      <>
        {
         history.location.state && history.location.state.data ? (
          <>
            {/* Following failed invite attempt */}
            <InviteAttempt/>
          </>
        ) : (
            <>
              {/* Ready to invite a new user */}
              {renderInvitePrompt(challengeDraft.invitee, props.nextMonthChallenge)}
              {/* <p className="mt-5">
                Invite {invitee.screenName !== '' ? invitee.screenName : 'user'} to your {props.nextMonthChallenge.startDate.toFormat('MMMM')} challenge {invitee.twitterId ? (<><>: </> <FollowerTarget twitterId={invitee.twitterId} date={DateTime.utc()} fixed={invitee.fixed} absoluteGain={invitee.absoluteGain} percentGain={invitee.percentGain}/> </>) : (<></>)}
              </p> */}
              <form className="form-inline" onSubmit={triggerInviteUser.bind(this)}>
                <div className="row">
                  <div className="col-10 col-md-8 col-lg-4">
                    <div className="input-group">
                      <div id="formContainer" className="form-floating">
                        <input 
                          id="screenName" 
                          className="form-control" 
                          onChange={updateInvitee.bind(this)} 
                          type="text" 
                          placeholder={challengeDraft.invitee.screenName}
                          value={screenName}
                          aria-describedby="inviteButton-addon"
                        />
                        <label className="form-control-placeholder" htmlFor="screenName">
                          Enter user screen name
                        </label>
                      </div>
                      <button className="btn btn-outline-dark align-self-start py-3" type="submit" id="inviteButton-addon" data-bs-toggle={responseModalText ? "modal" : ""} data-bs-target="#responseModal">Invite</button>
                    </div>
                    <ResponseModal screenName={screenName} responseModalText={responseModalText}/>
                  </div>
                  <div className={`col-10 col-md-8 mt-3 mt-lg-0 col-lg-4 ${validUser ? 'd-block' : 'd-none'}`}>
                    <FollowerGainInput self={false}/>
                  </div>
                </div>
              </form>
            </>
          )
        }
      </>
    )
  } else {
    return <LoadingPage/>
  }
}

export default ChallengeInviteForm