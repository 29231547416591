import React, { useState, useEffect } from 'react'
import Particles from 'react-tsparticles'
import { useSelector, shallowEqual } from 'react-redux'

import './ParticleBackground.css'
import { particleOptions } from '../options/particleOptions'

import { getTweetGoal, getCurrentChallenge } from '../selectors/challenges'
import { getUserTweetsWithDateMatch } from '../selectors/tweets'

const ParticleBackground = () => {
  const [auth, day, tweets, challenges] = useSelector(state => [state.auth, state.day, state.tweets, state.challenges], shallowEqual)
  const [timeFrame, setTimeFrame] = useState(day.startOf('month'))
  const [challengeTweets, setChallengeTweets] = useState(tweets)
  const [tweetGoal, setTweetGoal] = useState(getTweetGoal(getCurrentChallenge(challenges, auth ? auth.twitterId : null , day)))
  const [options, setOptions] = useState(particleOptions)
  const [toggle, setToggle] = useState(true)

  const particlesInit = () => {
    initializeOptions()
  }

  // Update challenge tweets when month changes
  useEffect(() => {
    if (tweets && auth && timeFrame) {
      setChallengeTweets(getUserTweetsWithDateMatch(tweets, auth.twitterId, timeFrame))
    }
  }, [tweets, auth, timeFrame])

  // Update timeFrame when day changes to new month
  useEffect(() => {
    if (!timeFrame.hasSame(day, 'month')) {
      setTimeFrame(day.startOf('month'))
    }
  }, [day, timeFrame])

  // Update tweetGoal when challenges change
  useEffect(() => {
    setTweetGoal(getTweetGoal(getCurrentChallenge(challenges, auth.twitterId, timeFrame)))
  }, [challenges, timeFrame, auth])

  useEffect(() => {
    initializeOptions()
    // eslint-disable-next-line
  }, [toggle, challengeTweets])

  const particlesLoaded = (container) => {
    // console.log(container);
  }

  const initializeOptions = () => {
    const opacityValue = challengeTweets && tweetGoal ? Math.round((0.5 + 0.3 * Math.min(1, challengeTweets.length/tweetGoal)) * 100)/100 : 0.5
    const particleNumber = options.particles.number.value
    if (auth.twitterId && toggle && particleNumber !== challengeTweets.length) {
      const updatedOptions = {
        ...options,
        particles: {
          ...options.particles,
          color: {
            ...options.particles.color,
            value: challengeTweets.length >= tweetGoal ? '#0d6efd' : '#AB7C94' 
          },
          links: {
            ...options.particles.links,
            color: {
              value: challengeTweets.length >= tweetGoal ? '#0d6efd' : '#AB7C94'
            },
            opacity: opacityValue
          },
          opacity: {
            ...options.particles.opacity,
            value: opacityValue
          },
          number: {
            ...options.particles.number,
            value: challengeTweets.length
          }
        }
      }
      setOptions(updatedOptions)
    }
  }

  const increaseSpeed = () => {
    if (options.particles.move.speed < 5) {
      const updatedOptions = {
        ...options,
        particles: {
          ...options.particles,
          move: {
            ...options.particles.move,
            speed: options.particles.move.speed + 1
          }
        }
      }
      setOptions(updatedOptions)
    }
  }

  const decreaseSpeed = () => {
    if (options.particles.move.speed > 0) {
      const updatedOptions = {
        ...options,
        particles: {
          ...options.particles,
          move: {
            ...options.particles.move,
            speed: options.particles.move.speed - 1
          }
        }
      }
      setOptions(updatedOptions)
    }
  }

  const toggleOnOff = () => {
    setToggle(!toggle)
    const updatedOptions = {
      ...options,
      particles: {
        ...options.particles,
        number: {
          ...options.particles.number,
          value: options.particles.number.value === 0 ? (auth ? challengeTweets.length : 80) : 0
        }
      }
    }
    setOptions(updatedOptions)
  }

  return (
    <>
      <div className="d-flex justify-content-end">
        <div className="btn-group" role="group">
          <button id="btnGroupDrop" className="btn dropdown-toggle particle-speed-command" data-bs-toggle="dropdown" aria-expanded="false"></button>
            <ul className="dropdown-menu" aria-labelledby="btnGroupDrop">
              <li className="dropdown-item">
                <div className="mx-auto">
                  {
                    options.particles.move.speed === 0 ? (
                      <></>
                    ) : (
                      <button onClick={decreaseSpeed} id="decrease" className="btn btn-outline-dark">-</button>
                    )
                  }
                  <span className="px-3">Particle Speed</span>
                  {
                    options.particles.move.speed === 5 ? (
                      <></>
                    ) : (
                      <button onClick={increaseSpeed} id="increase" className="btn btn-outline-dark">+</button>
                    )
                  }
                </div>
              </li>
              <li className="text-center">
                <button onClick={toggleOnOff} className="dropdown-item">Toggle particles {options.particles.number.value === (auth ? challengeTweets.length : 80) ? 'off' : 'on'}</button>
              </li>
            </ul>
        </div>
      </div>
      <Particles 
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={options}
      />
    </>
  )
}

export default ParticleBackground