import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { DateTime } from 'luxon'
import { withdrawInvite, withdrawFromChallenge, getChallenges, updateFollowerTarget } from '../actions/challenges'
import { getParticipants, getInvitees } from '../selectors/users'
import { getFollowersByDay } from '../selectors/snapshots'
import ParticipantStats from './ParticipantStats'
import FollowerTarget from './FollowerTarget'
import FollowerGainInput from './FollowerGainInput'
import LoadingPage from './LoadingPage'

const Challenge = (props) => {
  const [editEnabled, setEditEnabled] = useState(false)
  const dispatch = useDispatch()
  const [auth, users, snapshots] = useSelector(state => [state.auth, state.users, state.snapshots], shallowEqual)
  const [challenge, setChallenge] = useState(props.challenge)
  const [participants, setParticipants] = useState(getParticipants(challenge, users))
  const [invitees, setInvitees] = useState(getInvitees(challenge, users))
  const [followers, setFollowers] = useState([])

  // Update challenge
  useEffect(() => {
    if (challenge !== props.challenge) {
      setChallenge(props.challenge)
    }
  }, [challenge, props.challenge])

  // Update participants
  useEffect(() => {
    setParticipants(getParticipants(challenge, users))
  }, [challenge, users])

  // Update invitees
  useEffect(() => {
    setInvitees(getInvitees(challenge, users))
  }, [challenge, users])

  // Update followers
  useEffect(() => {
    if (auth && snapshots) {
      const followers = getFollowersByDay(snapshots, auth.twitterId, DateTime.utc())
      setFollowers(followers ? followers : [])
    }
  },[snapshots, auth])

  const editChallenge = (e) => {
    e.preventDefault()
    setEditEnabled(!editEnabled)
  }

  const triggerUpdateFollowerTarget = (e) => {
    dispatch(updateFollowerTarget(props.challenge))
  }

  const triggerWithdrawInvite = (e) => {
    const invitee = e.target.attributes.invitee.value
    dispatch(withdrawInvite(props.challenge, invitee))
  }

  const triggerWithdrawFromChallenge = (e) => {
    dispatch(withdrawFromChallenge(props.challenge))
    dispatch(getChallenges())
  }
  return (
    !participants && !invitees ? (
      <LoadingPage/>
    ) : (
      <div>
        <h6 className="mt-3">{props.challenge.startDate.toFormat('MMMM yyyy')} Challenge</h6>
        <p className="m-0">Tweets to win: {props.challenge.minTweets} per day</p>
        { 
          props.challenge.startDate > DateTime.utc() ? (
            <>
              {/* Include list of participants and invitees because this is an upcoming challenge */}
              {
                participants.map((participant) => (
                  <div className="row mt-2" key={participant.screenName}>
                    <div className="col-12 col-md-6">
                      {participant.screenName}: confirmed 
                      <p className={followers.length > 0 ? 'd-block' : 'd-none'}>Follower Target: <FollowerTarget twitterId={participant.twitterId} date={DateTime.utc()} fixed={participant.fixed} absoluteGain={participant.absoluteGain} percentGain={participant.percentGain} /></p>
                        <div className={editEnabled && participant.twitterId === auth.twitterId ? 'd-flex my-2' : 'd-none'}>
                          <FollowerGainInput creator={participant.creator}/>
                          <button className="btn btn-dark ms-3 align-self-start py-3" onClick={triggerUpdateFollowerTarget}>Submit</button>
                        </div>
                      { 
                      participant.twitterId === auth.twitterId ? (
                          <div className="mt-0 mb-3">
                            <button className="btn btn-dark me-1" onClick={editChallenge}>edit</button>
                            <button className="btn btn-dark" onClick={triggerWithdrawFromChallenge}>withdraw</button>
                          </div> 
                        ) : (
                          <></>
                        )
                      }
                    </div>
                  </div>
                ))
              }
              {
                invitees.filter((invitee) => invitee.accepted === undefined).map((invitee) => (
                  <div className="row my-1" key={invitee.screenName}>
                    <div className="col">
                      {invitee.screenName}: pending
                      {
                      auth.twitterId === invitee.invitedBy ? (
                        <div>
                          <button className="btn btn-dark" invitee={invitee.twitterId} onClick={triggerWithdrawInvite}>withdraw invite</button>
                        </div>
                        ) : (
                          <></>
                        )
                      }
                    </div>
                  </div>
                ))
              }
            </>
          ) : (
            <>
              {/* Include Scoreboard because this is a current challenge */}
              <p className="m-0">Scoreboard:</p>
              {
                participants.map((participant) => {
                  return (
                    <ParticipantStats key={participant.screenName} participant={participant} challenge={props.challenge}/>
                  )
                })
              }
            </>
          )
        }
      </div>
    )
  )
}

export default Challenge