import React from 'react'
import { useHistory } from 'react-router-dom'

const InviteAttempt = () => {
  const history = useHistory()

  const clearHistoryData = () => {
    history.push('/challenges', {data: ''})  
  }

  return (
    <div className="row">
      <div className="col-8">
        <p className="my-3">{history.location.state.data}<button className="btn btn-outline-dark mx-3" onClick={clearHistoryData}>Try again</button></p>
      </div>
    </div>
  )
}

export default InviteAttempt