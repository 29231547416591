import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import ChallengeMetrics from './ChallengeMetrics'
import FollowerTarget from './FollowerTarget'
import LoadingPage from './LoadingPage'

import { getChallenges, triggerCreateChallenge } from '../actions/challenges'
import { getTweetGoal } from '../selectors/challenges'
import { getFollowersByDay } from '../selectors/snapshots'

import './ChallengeDraftForm.css'

const ChallengeDraftForm = () => {
  const [auth, challengeDraft, snapshots] = useSelector(state => [state.auth, state.challengeDraft, state.snapshots], shallowEqual)
  const [tweetGoal, setTweetGoal] = useState(getTweetGoal(challengeDraft))
  const [followers, setFollowers] = useState([])
  const dispatch = useDispatch()

  // Update tweet goal
  useEffect(() => {
    setTweetGoal(getTweetGoal(challengeDraft))
  }, [challengeDraft])

  // Update followers
  useEffect(() => {
    if (auth) {
      const followers = getFollowersByDay(snapshots, auth.twitterId, DateTime.utc())
      setFollowers(followers ? followers : [])
    }
  }, [auth, snapshots])

  // create new challenge
  const createChallenge = () => {
    dispatch(triggerCreateChallenge(challengeDraft))
    dispatch(getChallenges())
  }
  if (!challengeDraft.startDate) {
    return <LoadingPage />
  }

  return (
    <>
      <p>
        Create new private challenge for {challengeDraft.startDate.toFormat('MMMM')} {challengeDraft.startDate.toFormat('yyyy')}
      </p>
      <div className="row">
        <div className="col-12 col-md-8">
          <form 
            onSubmit={createChallenge}
            className="p-3"
          >
            <ChallengeMetrics />
            <div className="row form-group">
                <div className="col-12 border-start border-end border-bottom p-2">
                  <div className="row">
                    <div className="d-none d-lg-block col-12 col-sm-5">
                      <h4 className="ms-4">Tips</h4>
                      <ol className="ms-3">
                        <li>Pick a realistic target</li>
                        <li>Invite friends</li>
                        <li>Celebrate the wins</li>
                      </ol>
                    </div>
                    <div className="col-12 col-sm-7">
                      <h4>Metrics</h4>
                      <p className="my-0">Days in Month: {challengeDraft.startDate.daysInMonth}</p>
                      <p className="my-0" style={{margin: '0px'}}>
                        {
                          ['0',''].indexOf(challengeDraft.minTweets) > -1 ? (
                            <>
                            </>
                          ) : (
                            <>
                              Tweets to Win: {tweetGoal}
                            </>
                          )
                        }
                      </p>
                      <p className={`my-0${followers.length === 0 || ['0',''].indexOf(challengeDraft.creator.absoluteGain) > -1 || ['0',''].indexOf(challengeDraft.creator.percentGain) > -1 ? 'd-none' : 'd-block'}`}>Follower Target: <FollowerTarget twitterId={auth.twitterId} date={DateTime.utc()} fixed={challengeDraft.creator.fixed} absoluteGain={challengeDraft.creator.absoluteGain} percentGain={challengeDraft.creator.percentGain}/></p>
                      {/* <p style={{margin: '0px'}}>Total stake: ${this.props.totalStake ? this.props.totalStake.toFixed(2) : '0.00'}</p> */}
                    </div>
                  </div>
                </div>
              </div>
            <input className="btn btn-dark mt-3" type="submit" value="Create" />            
          </form>
        </div>
      </div>
    </>
  )
}

export default ChallengeDraftForm