import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// import './index.css';
// import App from './App';
import configureStore from './store/configureStore'
import App from './components/App'
import LoadingPage from './components/LoadingPage'
import reportWebVitals from './reportWebVitals'

const store = configureStore()

const jsx = (
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

// Add code to update state with tweets from current user

// let hasRendered = false
// const renderApp = () => {
//   if (!hasRendered) {
//     ReactDOM.render(jsx, document.getElementById('root'))
//     hasRendered = true
//   }
// }

ReactDOM.render(<LoadingPage />, document.getElementById('root'))

// console.log(store.getState())

ReactDOM.render(
jsx,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()