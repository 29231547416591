const tweetsReducerDefaultState = []

const tweetsReducer = (state = tweetsReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOAD_TWEETS':
      return [
        ...action.tweets
      ]
    default:
      return state
  }
}

export default tweetsReducer