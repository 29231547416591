const snapshotsReducerDefaultState = []

const snapshotsReducer = (state = snapshotsReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOAD_SNAPSHOTS':
      return [
        ...action.snapshots
      ]
    default:
      return state
  }
}

export default snapshotsReducer