import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingInline from './LoadingInline'

import { getServerStats } from '../actions/stats'

const AdminPanel = () => {
    const stats = useSelector(state => state.stats)
    const [serverStats, setServerStats] = useState(stats.serverStats)
    const dispatch = useDispatch()

    // get server stats
    useEffect(() => {
        dispatch(getServerStats())
    }, [dispatch])

    // set server stats in local state
    useEffect(() => {
        setServerStats(stats.serverStats)
    }, [stats])

    return (
        <>
            <h3>AdminPanel</h3>
            <p>Signups: {serverStats ? serverStats.signedUp : <LoadingInline/>}</p>
        </>
    )
}

export default AdminPanel