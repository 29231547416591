import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { initiateDonation } from '../actions/stripe'

const donate = async (e) => {
  e.preventDefault()
  const productId = 'price_1JU83rDYOuuQuH0iv4T846VK'
  const res = await initiateDonation(productId)
  window.location = res
}

const DonationNonRecurring = () => (
  <section>
    <div className="donation-form">
      <form onSubmit={donate} >
        <button className="btn btn-outline-dark" type="submit">Donate</button>
      </form>
    </div>
  </section>
)

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
)

const DonationCheckout = () => {
  const {search} = useLocation()
  const message = useMemo(() => {
    //Check to see if this is a redirect back from Checkout
    let query = new URLSearchParams(search)
    if (query.get("success")) {
      return "Donation successful! Thank you so much for using TweepWars."
    }

    if (query.get("canceled")) {
      return "Donation failed. Please dm me @gibital on Twitter if this issue persists."
    }
  }, [search])

  return message ? (
    <Message message={message} />
  ) : (
    <DonationNonRecurring />
  )
}

export default DonationCheckout