import axios from 'axios'
import { DateTime } from 'luxon'

import { LOAD_STATS } from './types'

// LOAD_STATS
const loadStats = (stats) => ({
  type: LOAD_STATS,
  stats
})

// SET_DATE_RANGE
export const setDateRange = (startDate, endDate) => async (dispatch, getState) => {
  const stats = getState().stats
  const dateRange = {
    startDate,
    endDate
  }
  dispatch(loadStats({...stats, dateRange}))
}

// GET_FOLLOWER_COUNT
export const getFollowerStats = (twitterId, startDate, endDate) => async (dispatch, getState) => {
  const formattedStartDate = startDate.toFormat('yyyy-MM-dd')
  const formattedEndDate = endDate.toFormat('yyyy-MM-dd')
  const params = {
    startDate: formattedStartDate,
    endDate: formattedEndDate
  }
  const res = await axios.get(`/api/stats/followers/${twitterId}`, { params })
  if (res.status === 200) {
    const categoryData = res.data[twitterId][startDate.toFormat('yyyy-MM')].categoryData.map((date) => DateTime.fromISO(date, { zone: 'utc' }))
    const seriesData = res.data[twitterId][startDate.toFormat('yyyy-MM')].seriesData.map(item => {
      return {
        ...item,
        date: DateTime.fromISO(item.date, { zone: 'utc' })
      }
    })
    let followerGrowth = getState().stats.followerGrowth ? {...getState().stats.followerGrowth} : {}
    followerGrowth[twitterId] ? followerGrowth[twitterId] = {
      ...followerGrowth[twitterId]
    } : followerGrowth[twitterId] = {}

    followerGrowth[twitterId][startDate.toFormat('yyyy-MM')] = {
      categoryData,
      seriesData
      // forecastData
    }
    let stats = getState().stats
    dispatch(loadStats({...stats, followerGrowth}))
  } 
}

// GET_FOLLOWER_DELTA
export const getFollowerDelta = () => async (dispatch, getState) => {
  const stats = getState().stats

  const startDate = stats.dateRange.startDate
  const endDate = stats.dateRange.endDate

  const formattedStartDate = startDate.toFormat('yyyy-MM-dd')
  const formattedEndDate = endDate.toFormat('yyyy-MM-dd')
  const params = {
    startDate: formattedStartDate,
    endDate: formattedEndDate
  }
  const res = await axios.get('/api/stats/delta', { params })

  let delta = res.data

  if (res.status === 200) {
    dispatch(loadStats({...stats, delta}))
  } else if (res.status === 204) {
    console.log(`follower growth for selected timeframe is not available due to status ${res.status}`)
  }
}

// GET_SERVER_STATS
export const getServerStats = () => async (dispatch, getState) => {
  const stats = getState().stats
  const res = await axios.get('/api/stats/server')
  const serverStats = res.data
  if (res.status === 200) {
    dispatch(loadStats({...stats, serverStats}))
  } else {
    console.log(`getServerStats did not work with status ${res.status}`)
  }
}

// GET_BADGES
export const getBadges = () => async (dispatch, getState) => {
  const stats = getState().stats
  const res = await axios.get('/api/badges')
  const badges = res.data
  if (res.status === 200) {
    dispatch(loadStats({...stats, badges}))
  } else {
    console.log(`getBadges did not work with status ${res.status}`)
  }
}

// GET_STREAKS
export const getStreaks = () => async (dispatch, getState) => {
  const stats = getState().stats
  const res = await axios.get('/api/streaks')
  const streaks = res.data
  if (res.status === 200) {
    dispatch(loadStats({...stats, streaks}))
  } else {
    console.log(`getStreaks did not work with status ${res.status}`)
  }
}