import React from 'react';

const FollowerProgressBar = (props) => {
  const lowestPoint = Math.min(props.followersStart, props.followersEnd)
  const target = props.followersStart + props.absoluteGain
  const startingWidth = lowestPoint / target * 100
  const growthWidth = Math.max(props.followersGain, 0)/ target * 100
  const lossWidth = Math.min(props.followersGain, 0) / target * 100 * -1
  const targetDeltaWidth = ((props.absoluteGain - Math.max(props.followersGain,0))/target) * 100
  const currentDelta = Math.max(props.followersEnd, props.followersStart)
  return (
    <>
      <p className="m-0">{(Math.abs(props.followersGain)).toString()} followers {props.followersEnd >= props.followersStart ? 'gained towards' : 'lost against'} target of {target}</p>
      <div className="progress">
        <div className="progress-bar bg-secondary" style={{width: `${startingWidth}%`}} role="progressbar" aria-valuenow={lowestPoint} aria-valuemin={0} aria-valuemax={props.followersStart}>{startingWidth > 2 ? lowestPoint : ''}</div>
        <div className="progress-bar" style={{width: `${growthWidth}%`}} role="progressbar" aria-valuenow={props.followersEnd} aria-valuemin={props.followersStart} aria-valuemax={target}>{growthWidth > 2 ? props.followersGain : ''}</div>
        <div className="progress-bar bg-danger" style={{width: `${lossWidth}%`}} role="progressbar" aria-valuenow={props.followersEnd} aria-valuemin={props.followersEnd} aria-valuemax={target}>{lossWidth > 2 ? Math.abs(props.followersGain) : ''}</div>
        <div id="incomplete" className="progress-bar" role="progressbar" style={{width: `${targetDeltaWidth}%`}} aria-valuenow={currentDelta} aria-valuemin={currentDelta} aria-valuemax={target}>{targetDeltaWidth > 2 ? lowestPoint + props.absoluteGain - props.followersEnd : ''}</div>
      </div>
    </>
  )
}

export default FollowerProgressBar;
