import axios from 'axios'
import { DateTime } from 'luxon'

import { LOAD_CHALLENGES, CREATE_CHALLENGE, UPDATE_CHALLENGE } from './types'

// LOAD_CHALLENGES
export const loadChallenges = (challenges) => ({
  type: LOAD_CHALLENGES,
  challenges
})

export const getChallenges = () => async (dispatch, getState) => {
  const res = await axios.get('/challenges/load/all')
  const challenges = res.data
  dispatch(loadChallenges(challenges.map((challenge) => {
    return {
      id: challenge._id,
      startDate: DateTime.fromISO(challenge.startDate, {zone: 'utc'}),
      endDate: DateTime.fromISO(challenge.endDate, {zone: 'utc'}),
      minTweets: challenge.minTweets,
      stakePerTweet: challenge.stakePerTweet,
      participants: challenge.participants.map((participant) => {
        return {
          twitterId: participant.twitterId,
          absoluteGain: participant.absoluteGain,
          percentGain: participant.percentGain,
          fixed: participant.fixed,
          creator: participant.creator
        }
      }),
      invites: challenge.invites.map((invite) => {
        return {
          invitee: invite.invitee,
          invitedBy: invite.invitedBy,
          accepted: invite.accepted,
          fixed: invite.fixed,
          percentGain: invite.percentGain,
          absoluteGain: invite.absoluteGain
        }
      })
    }
  }).sort((a, b) => {
    return a.startDate < b.startDate ? 1 : -1
  })))
}

// CREATE_CHALLENGE
export const createChallenge = (challenge) => ({
  type: CREATE_CHALLENGE,
  challenge
})

export const triggerCreateChallenge = () => async (dispatch, getState) => {
  const challenge = getState().challengeDraft
  const res = await axios({
    method: 'post',
    url: '/challenges/create',
    data: challenge
  })
  dispatch(createChallenge(res))
}

// UPDATE_CHALLENGE
export const updateChallenge = (challenge) => ({
  type: UPDATE_CHALLENGE,
  challenge
})

export const inviteUser = (challenge, userId) => async (dispatch, getState) => {
  let updatedChallenge = challenge
  const twitterId = getState().auth.twitterId
  const invitee = getState().challengeDraft.invitee
  const { fixed, absoluteGain, percentGain } = invitee
  const data = {
    fixed, 
    absoluteGain, 
    percentGain
  }
  await axios.put(`/challenges/${challenge.id.toString()}/invite/${userId}`, data)
  updatedChallenge.invites = [
    ...challenge.invites,
    {
      invitee: userId,
      invitedBy: twitterId,
      fixed,
      absoluteGain,
      percentGain
    }
  ]
  dispatch(updateChallenge(updatedChallenge))
}

// UPDATE_FOLLOWER_TARGET
export const updateFollowerTarget = (challenge) => async (dispatch, getState) => {
  const challengeDraft = getState().challengeDraft
  const creator = challengeDraft.creator
  const { fixed, absoluteGain, percentGain } = creator
  const twitterId = getState().auth.twitterId
  await axios.put(`/challenges/${challenge.id.toString()}/update/followerTarget`, challengeDraft)
  const updatedChallenge = challenge
  updatedChallenge.participants = updatedChallenge.participants.map((participant) => {
    if (participant.twitterId === twitterId) {
      participant.fixed = fixed
      participant.absoluteGain = absoluteGain
      participant.percentGain = percentGain
    }
    return participant
  })
  dispatch(updateChallenge(updatedChallenge))
} 


// WITHDRAW_INVITE
export const withdrawInvite = (challenge, userId) => async (dispatch, getState) => {
  let updatedChallenge = challenge
  await axios.put(`/challenges/${challenge.id.toString()}/uninvite/${userId}`)
  updatedChallenge.invites = challenge.invites.filter(invite => invite.invitee !== userId)
  dispatch(updateChallenge(updatedChallenge))
}

// ACCEPT_INVITE
export const acceptInvite = (challenge, userId) => async (dispatch) => {
  let updatedChallenge = challenge
  function findInvite (challenge, userId) {
    const invites = challenge.invites
    for (let i = 0; i < invites.length; i++) {
      const invite = invites[i]
      if (invite.invitee === userId) {
        return invite
      }
    }
  }
  const invite = findInvite(challenge, userId)
  const { absoluteGain, percentGain, fixed } = invite

  const data = {
    absoluteGain,
    percentGain,
    fixed
  }

  await axios.put(`/challenges/${challenge.id.toString()}/accept/${userId}`, data)
  updatedChallenge.invites = challenge.invites.map((invite) => {
    if (invite.invitee === userId) {
      updatedChallenge.participants.push({
        twitterId: userId,
        absoluteGain,
        percentGain,
        fixed
      })
      return {
        ...invite,
        accepted: true
      }
    } else {
      return invite
    }
  })
  dispatch(updateChallenge(updatedChallenge))
}

// DECLINE_INVITE
export const declineInvite = (challenge, userId) => async (dispatch) => {
  let updatedChallenge = challenge
  await axios.put(`/challenges/${challenge.id.toString()}/decline/${userId}`)
  updatedChallenge.invites = challenge.invites.map((invite) => {
    if (invite.invitee === userId) {
      return {
        ...invite,
        accepted: false
      }
    } else {
      return invite
    }
  })
  dispatch(updateChallenge(updatedChallenge))
}

export const withdrawFromChallenge = (challenge) => async (dispatch, getState) => {
  let updatedChallenge = challenge
  const twitterId = getState().auth.twitterId
  await axios.put(`/challenges/${challenge.id.toString()}/withdraw`)
  updatedChallenge.participants = challenge.participants.filter((participant) => participant !== twitterId)
  dispatch(updateChallenge(updatedChallenge))
}