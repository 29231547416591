import React, { useEffect, useState } from 'react'
import { Route, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getUser } from '../actions/index'
import Loading from './LoadingPage'

const AdminUnauthorized = () => {
    return (
        <div style={{ textAlign: 'center' }}>
          <h1>Unauthorized</h1>
          <p>You need admin privileges to access this page.</p>
          <p>Return <Link className="link-dark" to="/">Home</Link></p>
        </div>
      )
}

const AdminRoute = ({path, component}) => {
    const auth = useSelector(state => state.auth)
    const [adminRights] = useState(auth.admin)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!adminRights) {
            dispatch(getUser())
        }
    }, [adminRights, dispatch])

    if (auth) {
        if (auth === 'ready') {
            return <></>
        }
        if (auth === 'pending') {
            return <Loading/>
        }
        if (auth.admin) {
            return <Route path={path} component={component}/>
        }
    }
    return <AdminUnauthorized />
}

export default AdminRoute