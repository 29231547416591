import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { setDay } from '../actions/dates'
import { getUserSnapshotByDay } from '../actions/snapshots'
import { getFollowerStats } from '../actions/stats'
import { getUserTweetsWithDateMatch } from '../selectors/tweets'
import { getCurrentChallenge } from '../selectors/challenges'
import './DayTrigger.css'

const DayTrigger = ({ detailView = true, buttonContent, border, selectedDay}) => {
  const [auth, challenges, day, tweets] = useSelector(state => [state.auth, state.challenges, state.day, state.tweets], shallowEqual)
  const [currentChallenge, setCurrentChallenge] = useState(getCurrentChallenge(challenges, auth.twitterId, day))
  const [minTweets, setMinTweets] = useState(currentChallenge ? currentChallenge.minTweets : null)
  const [todaysTweets, setTodaysTweets] = useState(getUserTweetsWithDateMatch(tweets, auth.twitterId, selectedDay, 'day'))
  const dispatch = useDispatch()

  // Update current challenge
  useEffect(() => {
    setCurrentChallenge(getCurrentChallenge(challenges, auth.twitterId, day))
  }, [challenges, auth, day])

  // Update min tweets
  useEffect(() => {
    setMinTweets(currentChallenge ? currentChallenge.minTweets : null)
  }, [currentChallenge])

  // Update todays tweets
  useEffect(() => {
    setTodaysTweets(getUserTweetsWithDateMatch(tweets, auth.twitterId, selectedDay, 'day'))
  }, [tweets, auth, selectedDay])

  const onClick = () => {
    dispatch(setDay(selectedDay))
    dispatch(getUserSnapshotByDay(auth.twitterId, selectedDay))
    dispatch(getUserSnapshotByDay(auth.twitterId, selectedDay.plus({ days: -1})))
    if (!day.hasSame(selectedDay, 'month')) {
      dispatch(getFollowerStats(auth.twitterId, selectedDay.startOf('month'), selectedDay.endOf('month')))
    }
  }
  
  return (
    <div className="mx-auto" style={{ width: '95%' }}>
      {
        detailView && currentChallenge ? (
          <div style={{border: border, display: 'flex', alignItems: 'flex-end', height: '40px', zIndex: 1 }}>
            {
              !['<','>'].some((string) => string === buttonContent) ? (
                <div style={{ height: `${Math.min(38,todaysTweets.length * 38 / minTweets)}px`, backgroundColor: Math.min(38,todaysTweets.length * 38 / minTweets) === 38 ? '#0d6efd' : '#AB7C94', width: '100%', zIndex: 5 }}>
                </div>
              ) : (
                <>
                </>
              )
            }

          </div>
        ) : (
          <></>
        )
      }
      <Link to={`/statistics/${selectedDay.toFormat('yyyy/MM/dd')}`}>
        <button 
          className="btn btn-outline-dark btn-sm text-nowrap"
          style={{width: '100%'}}
          disabled={selectedDay > DateTime.utc()}
          onClick={onClick}
        >
          <span className="dailyCard mx-auto">{buttonContent}</span>
        </button>
      </Link>
    </div>
  )
}

export default DayTrigger