// get array of current week from selected day
export const getCurrentWeek = (selectedDay) => {
  let currentWeek = []
  let date = selectedDay.startOf('week').plus({days: -1})
  for (let i = 0; i < 7; i++) {
    date = date.plus({days: 1})
    currentWeek.push(date)
  }
  return currentWeek
}

// get array of two weeks from selected day
export const getCurrentFortnight = (selectedDay) => {
  let currentFortnight = []
  let date = selectedDay.startOf('week').plus({days: -1})
  for (let i = 0; i < 14; i++) {
    date = date.plus({days: 1})
    currentFortnight.push(date)
  }
  return currentFortnight
}

// get Date array
export const getDateArray = (selectedDay, days) => {
  let currentDateArray = []
  const startOfMonth = selectedDay.startOf('month')
  const daysAgo = selectedDay.plus({days: -days})
  let date = startOfMonth <= daysAgo ? daysAgo : startOfMonth.plus({days: -1})
  for (let i = 0; i < days; i++) {
    date = date.plus({days: 1})
    currentDateArray.push(date)
  }
  return currentDateArray
}


