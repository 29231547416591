import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import { setCreator, setInvitee } from '../actions/challengeDraft'
import { getFollowersByDay } from '../selectors/snapshots'

import './FollowerGainInput.css'

const FollowerGainInput = ({creator}) => {
  const [auth, challengeDraft, snapshots] = useSelector(state => [state.auth, state.challengeDraft, state.snapshots], shallowEqual)
  const [followers, setFollowers] = useState([])
  const [inviteeFollowers, setInviteeFollowers] = useState([])
  const dispatch = useDispatch()

  // Update followers
  useEffect(() => {
    const followers = getFollowersByDay(snapshots, auth.twitterId, DateTime.utc())
    setFollowers(followers ? followers : [])
  }, [snapshots, auth])

  // Update inviteeFollowers
  useEffect(() => {
    const inviteeFollowers = getFollowersByDay(snapshots, challengeDraft.invitee.twitterId, DateTime.utc())
    setInviteeFollowers(inviteeFollowers ? inviteeFollowers : [])
  }, [snapshots, challengeDraft])

  const toggleFixed = (e) => {
    e.preventDefault()
    if (creator) {
      const fixed = challengeDraft.creator.fixed === 'absolute' ? 'percent' : 'absolute'
      dispatch(setCreator({
        ...challengeDraft.creator,
        fixed
      }))
    } else {
      const fixed = challengeDraft.invitee.fixed === 'absolute' ? 'percent' : 'absolute'
      dispatch(setInvitee({
        ...challengeDraft.invitee,
        fixed
      }))
    }
  }

  const calculateGain = (fixed, targetValue, followers) => {
    let gains = {}
    if (fixed === 'absolute') {
      gains['absoluteGain'] = targetValue
      if (followers.length > 0) {
        gains['percentGain'] = Math.round(targetValue/followers.length * 100)
      } else {
        gains['percentGain'] = 100
      }
    }
    if (fixed === 'percent') {
      gains['percentGain'] = targetValue
      if (followers.length > 0) {
        gains['absoluteGain'] = Math.round(targetValue / 100 * followers.length)
      } else {
        gains['absoluteGain'] = 1
      }
    }
    return gains
  }
  const setFollowerGain = (e) => {
    e.preventDefault()
    if (creator) {
      const fixed = challengeDraft.creator.fixed
      const gains = calculateGain(fixed, e.target.value, followers)
      dispatch(setCreator({
        ...challengeDraft.creator,
        percentGain: gains['percentGain'],
        absoluteGain: gains['absoluteGain']
      }))
    } else {
      const fixed = challengeDraft.invitee.fixed
      const gains = calculateGain(fixed, e.target.value, inviteeFollowers)
      dispatch(setInvitee({
        ...challengeDraft.invitee,
        percentGain: gains['percentGain'],
        absoluteGain: gains['absoluteGain']
      }))
    }
  }
  return (
    <div id="inputContainer" className="input-group has-validation">
      <div id="formContainer" className="form-floating">
          <input
            className={`form-control ${creator ? 'text-center' : 'text-start'} ${creator ? ((challengeDraft.creator.absoluteGain && challengeDraft.creator.absoluteGain > 0 && challengeDraft.creator.percentGain && challengeDraft.creator.percentGain >= 0) ? '' : 'is-invalid') : ((challengeDraft.invitee.absoluteGain && challengeDraft.invitee.absoluteGain > 0 && challengeDraft.invitee.percentGain && challengeDraft.invitee.percentGain > 0) ? '' : 'is-invalid')}`}
            id="floatingInputTwo"
            type="Number"
            placeholder={ creator ? (challengeDraft.creator.fixed === 'absolute' ? challengeDraft.creator.absoluteGain : challengeDraft.creator.percentGain) : (challengeDraft.invitee.fixed === 'absolute' ? challengeDraft.invitee.absoluteGain : challengeDraft.invitee.percentGain) }
            value={ creator ? (challengeDraft.creator.fixed === 'absolute' ? challengeDraft.creator.absoluteGain : challengeDraft.creator.percentGain) : (challengeDraft.invitee.fixed === 'absolute' ? challengeDraft.invitee.absoluteGain : challengeDraft.invitee.percentGain) }
            onChange={setFollowerGain.bind(this)}
            min="1"
            step="1"
            aria-describedby="button-addon1"
            required
          />
          <div id="floatingInputTwoFeedback" className="invalid-feedback">Input must be greater than zero</div>
        <label className="form-control-placeholder" htmlFor="floatingInputTwo">
          Follower Gain {creator ? (challengeDraft.creator.fixed === 'absolute' ? '' : '(%)') : (challengeDraft.invitee.fixed === 'absolute' ? '' : '(%)')}
        </label>
      </div>
      <button onClick={toggleFixed.bind(this)} className="btn btn-outline-dark align-self-start py-3" type="button" id="button-addon1">{creator ? challengeDraft.creator.fixed : challengeDraft.invitee.fixed}</button>
    </div>
  )
}

export default FollowerGainInput