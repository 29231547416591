import React from 'react';
import { useSelector } from 'react-redux'
import { Link, Redirect } from 'react-router-dom';

import Loading from './LoadingPage'


const Unauthorized = (props) => {
  const auth = useSelector(state => state.auth)
  console.log('Unauthorized', auth)
  console.log('props location', props.location)
  if (auth === 'ready') {
    return <></>
  }
  if (auth === 'pending') {
    return <Loading/>
  }
  if (!auth) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1>Unauthorized</h1>
        <p>You need to be logged in to access this page.</p>
        <p>Return <Link className="link-dark" to="/">Home</Link></p>
      </div>
    )
  } else {
    let path
    if (props.location.state) {
      path = props.location.state.path
    } else {
      path = props.location.pathname
    }
    return (
      <Redirect to={path} />
    )
  }
}

export default Unauthorized;