import axios from 'axios'
import { GET_USER_START, GET_USER_SUCCESS, HANDLE_TOKEN } from './types'
import { getUsers } from './users'
import { getChallenges } from './challenges'
import { setDay } from './dates'
import { initializeDraft } from './challengeDraft'
import { getSnapshots, getUserSnapshotByDay } from './snapshots'
import { getFollowerStats, getFollowerDelta, setDateRange, getServerStats } from './stats'

export const getUser = () => ( async (dispatch) => {
    dispatch({ type: GET_USER_START })
    const res = await axios.get('/api/current_user')
    dispatch({ type: GET_USER_SUCCESS, payload: res.data })
    return res.data
  }
)

export const handleToken = (token) => async (dispatch) => {
  const res = await axios.post('/api/stripe', token)
  dispatch({ type: HANDLE_TOKEN, payload: res.data })
}

export const loadChallenges = getChallenges
export const loadUsers = getUsers
export const initialDay = setDay
export const initializeChallengeDraft = initializeDraft
export const loadSnapshots = getSnapshots
export const getSnapshot = getUserSnapshotByDay
export const loadFollowerStats = getFollowerStats
export const loadFollowerDelta = getFollowerDelta
export const initializeDateRange = setDateRange
export const loadServerStats = getServerStats
