
// get followers by day
export const getFollowersByDay = (snapshots, twitterId, date) => {
  const snapshot = snapshots.find((snapshot) => {
    return snapshot.twitterId === twitterId && snapshot.timestamp.hasSame(date, 'day')
  })
  const followers = snapshot ? snapshot.followers : undefined 
  return followers
}

// get previous snapshot
export const getPreviousSnapshot = (snapshots, twitterId, date) => {
  const snapshotsBeforeDate = snapshots.filter((snapshot) => snapshot.twitterId === twitterId && snapshot.timestamp <= date)
  const sortedSnapshots = snapshotsBeforeDate.sort((a, b) => b.timestamp - a.timestamp)
  const previousSnapshot = sortedSnapshots[0]
  return previousSnapshot
}