import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { extendInvite } from '../actions/invite'

const ResponseModal = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleInvite = (screenName, history) => {
    const res = dispatch(extendInvite(screenName, history))
    history.push('/challenges', {data: res.data})  
  }

  return (
    <div className="modal fade" id="responseModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Invite failed</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {props.responseModalText}
          </div>
          <div className="modal-footer">
            <button type="button" onClick={handleInvite.bind(null, props.screenName, history)} className={`btn btn-outline-dark ${props.responseModalText.match('has not registered an account with TweepWars') ? 'd-block' : 'd-none'}`} data-bs-dismiss="modal">Invite {props.screenName}</button>
            <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResponseModal
