import axios from 'axios'
import { DateTime } from 'luxon'

import { LOAD_SNAPSHOTS } from './types'

// LOAD_SNAPSHOTS
export const loadSnapshots = (snapshots) => ({
  type: LOAD_SNAPSHOTS,
  snapshots
})

// GET_SNAPSHOTS
export const getSnapshots = () => async (dispatch, getState) => {
  const res = await axios.get('/api/snapshots')
  const rawSnapshots =res.data
  const snapshots = rawSnapshots.map((snapshot) => {
    snapshot.timestamp = DateTime.fromISO(snapshot.timestamp, {zone: 'utc'})
    return snapshot
  })
  dispatch(loadSnapshots(snapshots))
}

// GET_RECENT_SNAPSHOTS
export const getRecentSnapshots = () => async (dispatch, getState) => {
  const res = await axios.get('/api/recent_snapshots')
  const rawSnapshots = res.data
  const snapshots = rawSnapshots.map((snapshot) => {
    snapshot.timestamp = DateTime.fromISO(snapshot.timestamp, {zone: 'utc'})
    return snapshot
  })
  dispatch(loadSnapshots(snapshots))
}

// GET_USER_SNAPSHOT_BY_DAY
export const getUserSnapshotByDay = (twitterId, date) => async (dispatch, getState) => {
  let snapshots = getState().snapshots
  const alreadyRetrieved = snapshots.some((snapshot) => {
    if (snapshot.timestamp.hasSame(date, 'day') && twitterId === snapshot.twitterId) {
      return true
    } else {
      return false
    }
  })

  const formattedDay = date.toFormat('yyyy-MM-dd')
  if (!alreadyRetrieved) {
    const res = await axios.get(`/api/snapshot/${twitterId}/${formattedDay}`)
    if (res.data) {
      const snapshot = res.data
      snapshot.timestamp = DateTime.fromISO(snapshot.timestamp, {zone: 'utc'})
      const snapshotIds = snapshots.map(snapshot => snapshot._id)
      const previouslyRetrieved = snapshotIds.includes(snapshot._id)
      if (previouslyRetrieved) {
        // nothing
      } else {
        snapshots = [...snapshots, snapshot]
        dispatch(loadSnapshots(snapshots))
      }
    }
  }
}