import React, { useState, useEffect } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { DateTime } from 'luxon'
import { getFollowerStats } from '../actions/stats'
import { getTweetsCurrent } from '../actions/tweets'
import { setDay } from '../actions/dates'

import ChallengeOverview from './ChallengeOverview'
import DayDashboard from './DayDashboard'
import DayTrigger from './DayTrigger'
import LoadingPage from './LoadingPage'
import ChallengeInduced from './ChallengeInduced'

import { getCurrentChallenge } from '../selectors/challenges'
import { getDateArray } from '../selectors/dates'
import './Day.css'

const Day = () => {
  const [auth, selectedDay, stats, currentChallenge, week, fortnight, threeWeeks, month] = useSelector(state => [state.auth, state.day, state.stats, getCurrentChallenge(state.challenges, state.auth ? state.auth.twitterId : null , state.day), getDateArray(state.day, 7) ? getDateArray(state.day, 7) : [], getDateArray(state.day, 14) ? getDateArray(state.day, 14) : [], getDateArray(state.day, 21) ? getDateArray(state.day, 21) : [], getDateArray(state.day, state.day.daysInMonth) ? getDateArray(state.day, state.day.daysInMonth) : []], shallowEqual)
  const [timeFrame, setTimeFrame] = useState(selectedDay.startOf('month'))
  const params = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [detailView] = useState(true)

  const updateURL = () => {
    const now = DateTime.utc()
    history.replace(`/statistics/${now.toFormat('yyyy')}/${now.toFormat('MM')}/${now.toFormat('dd')}`, {})
  }

  useEffect(() => {
    if (!timeFrame.hasSame(selectedDay,'month')) {
      setTimeFrame(selectedDay.startOf('month'))
    }
  }, [selectedDay, timeFrame])

  useEffect(() => {
    dispatch(getTweetsCurrent(timeFrame, timeFrame.endOf('month')))
  }, [dispatch, timeFrame])


  useEffect(() => {
    let paramDay
    if (isNaN(params.yyyy)) {
      updateURL()
      paramDay = DateTime.utc()
    } else {
      paramDay = DateTime.utc(parseInt(params.yyyy), parseInt(params.MM), parseInt(params.dd))  
    }

    dispatch(setDay(paramDay))
    if (auth && (!stats.followerGrowth || (stats.followerGrowth && !stats.followerGrowth[paramDay.toFormat('yyyy-MM')]))) {
      const twitterId = auth.twitterId
      const startDate = selectedDay.startOf('month')
      const endDate = selectedDay.endOf('month')
      dispatch(getFollowerStats(twitterId, startDate, endDate))
    }
    // eslint-disable-next-line
  }, [auth])

  if (!auth || !selectedDay) {
    return (
      <LoadingPage/>
    )
  }
  return (
    <div>
      {
        !currentChallenge ? (
          <>
            No challenge created, but if every day looked like your best day ...
            <ChallengeInduced/>
          </>
        ) : (
        <ChallengeOverview/>
        )
      }

      <div id="monthly-progress" className="mb-3">
        {/* Full week for mobile */}
        <div className="card-group mt-1 d-flex justify-content-center d-md-none">
          <div id="jump-back" className="monthpicker">
            <DayTrigger 
              selectedDay={week[0].plus({ days:-1 })}
              buttonContent='<'
              border="none"
              detailView={detailView}
            />
          </div>
          {
            week.map((date) => (
              <div 
                className="card"
                key={date.day}
                style={{flex: '1 1 0', width: '0'}}
              >
                <DayTrigger 
                  selectedDay={DateTime.utc(date.year, date.month, date.day)}
                  buttonContent={date.day}
                  border="1px solid black"
                  detailView={detailView}
                />
              </div>
            ))
          }
          <div id="jump-forward" className="monthpicker">
            <DayTrigger 
              selectedDay={week[week.length-1].plus({ days: 1 })}
              buttonContent='>'
              border="none"
              detailView={detailView}
            />
          </div>
        </div>
        {/* Full fortnight for medium screens */}
        <div className="card-group mt-1 d-none justify-content-center d-md-flex d-lg-none">
          <div id="jump-back" className="monthpicker">
            <DayTrigger 
              selectedDay={fortnight[0].plus({ days: -1 })}
              buttonContent='<'
              border="none"
              detailView={detailView}
            />
          </div>
          {
            fortnight.map((date) => (
              <div 
                className="card"
                key={date.day}
                style={{flex: '1 1 0'}}
              >
                <DayTrigger 
                  selectedDay={DateTime.utc(date.year, date.month, date.day)}
                  buttonContent={date.day}
                  border="1px solid black"
                  detailView={detailView}
                />
              </div>
            ))
          }
          <div id="jump-forward" className="monthpicker">
            <DayTrigger 
              selectedDay={fortnight[fortnight.length-1].plus({ days: 1 })}
              buttonContent='>'
              border="none"
              detailView={detailView}
            />
          </div>
        </div>
        {/* Three weeks for large screens */}
        <div className="card-group mt-1 d-none justify-content-center d-lg-flex d-xl-none">
          <div id="jump-back" className="monthpicker">
            <DayTrigger 
              selectedDay={threeWeeks[0].plus({ days:-1 })}
              buttonContent='<'
              border="none"
              detailView={detailView}
            />
          </div>
          {
            threeWeeks.map((date) => (
              <div 
                className="card"
                key={date.day}
                style={{flex: '1 1 0'}}
              >
                <DayTrigger 
                  selectedDay={DateTime.utc(date.year, date.month, date.day)}
                  buttonContent={date.day}
                  border="1px solid black"
                  detailView={detailView}
                />
              </div>
            ))
          }
          <div id="jump-forward" className="monthpicker">
            <DayTrigger 
              selectedDay={threeWeeks[threeWeeks.length-1].plus({ days: 1 })}
              buttonContent='>'
              border="none"
              detailView={detailView}
            />
          </div>
        </div>
        {/* Whole month for extra large screens */}
        <div className="card-group mt-1 d-none d-xl-flex">
          <div id="jump-back" className="monthpicker">
            <DayTrigger 
              selectedDay={selectedDay.plus({ months: -1 }).endOf('month')}
              buttonContent='<'
              border="none"
              detailView={detailView}
            />
          </div>
          {
            month.map((date) => (
              <div 
                className="card"
                key={date.day}
                style={{flex: '1 1 0'}}
              >
                <DayTrigger 
                  selectedDay={DateTime.utc(date.year, date.month, date.day)}
                  buttonContent={date.day}
                  border="1px solid black"
                  detailView={detailView}
                />
              </div>
            ))
          }
          {
            selectedDay.endOf('month') > DateTime.utc() ? (
              <>
              </>
            ) : (
              <div id="jump-forward" className="monthpicker">
                <DayTrigger 
                  selectedDay={selectedDay.plus({ months: 1 }).startOf('month')}
                  buttonContent='>'
                  border="none"
                  detailView={detailView}
                />
              </div>

            )
          }
        </div>
      </div>
      <DayDashboard 
        key={1}
        day={selectedDay}
        detailView={detailView}
      />
    </div>
  )
}

export default Day