import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga'

import AdminPanel from './AdminPanel'
import ParticleBackground from './ParticleBackground'
import Header from './Header'
import Home from './Home'
import ChallengeDashboard from './ChallengeDashboard'
import FeatureRequests from './FeatureRequests'
import Donate from './Checkout'
import Day from './Day'
import Statistics from './Statistics'
import Footer from './Footer'
import Terms from './Terms'
import NotInvited from './NotInvited'
import Unauthorized from './Unauthorized'
import PrivateRoute from './PrivateRoute'
import AdminRoute from './AdminRoute'
import OffcanvasHandler from './OffcanvasHandler'
const Settings = () => <h2>Settings</h2>

const UniversalAnalyticsTrackingId = process.env.NODE_ENV === 'development' ? 'UA-209759725-2' : 'UA-209759725-1'

// Unique Universal Analytics Tracking Id
ReactGA.initialize(UniversalAnalyticsTrackingId)

const App = () => {
  // register new page view when App reloads
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    
  })

  return (
    <BrowserRouter>
      <div>
        <ParticleBackground/>
        <Header />
        <div className="mb-5 py-5">
          <Switch>
            <Route exact path="/" component={Home} />
            <PrivateRoute path="/challenges" component={ChallengeDashboard} />
            <PrivateRoute path="/feature-requests" component={FeatureRequests} />
            <PrivateRoute path="/statistics/followers/:yyyy/:MM/:dd" component={Statistics} />
            <PrivateRoute path="/statistics/:yyyy/:MM/:dd" component={Day} />
            <Route path="/terms-and-conditions" component={Terms}/>
            <PrivateRoute path="/settings" component={Settings} />
            <PrivateRoute path="/donate" component={Donate}/>
            <AdminRoute path="/admin" component={AdminPanel}/>
            <Route path="/not-invited" component={NotInvited}/>
            <Route path="/unauthorized" component={Unauthorized}/>
          </Switch>
        </div>
        {<OffcanvasHandler />}
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App