import React from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { Redirect } from 'react-router-dom'

import FollowerBreakdown from './FollowerBreakdown'
import DateSelector from './DateSelector'

const Statistics = (props) => {
  const state = useSelector((state) => state)
  const date = DateTime.fromISO(`${props.match.params.yyyy}-${props.match.params.MM}-${props.match.params.dd}`, { zone: 'utc' })

  const renderStats = () => {
    return (
      <div>
        {`Follower Statistics for ${state.auth.screenName} on ${date.toFormat('MMMM dd, yyyy')}`}
        <DateSelector />
        <FollowerBreakdown date={date}/>
      </div>
    )
  }

  if(isNaN(props.match.params.yyyy)) {
    const now = DateTime.utc()
    return (
      <div>
        <Redirect to={`/statistics/followers/${now.toFormat('yyyy')}/${now.toFormat('MM')}/${now.toFormat('dd')}`} />
      </div>
    )
  }

  return (
    <div>
      {renderStats()}
    </div>
  )
}

export default Statistics