import React from 'react'
import { DateTime } from 'luxon'

import FollowerChart from './FollowerChart'

const FollowerChartHandler = ({day, stats}) => {
    console.log('day', day)
    return (
        <>
            {/* Full week for mobile */}
            <div className="d-block d-md-none">
            <div className={stats.followerGrowth ? "followerChartContainer d-flex justify-content-center" : "d-none"}>
                <div style={{ width: `${100 * 7/9}%`}}>
                <FollowerChart />
                </div>
            </div>
            </div>
            {/* Full fortnight for medium screens */}
            <div className="d-none d-md-block d-lg-none">
            <div className={stats.followerGrowth ? "followerChartContainer d-flex justify-content-center" : "d-none"}>
                <div style={{ width: `${100 * 14/16}%`}}>
                <FollowerChart />
                </div>
            </div>
            </div>

            {/* Three weeks for large screens */}
            <div className="d-none d-lg-block d-xl-none">
            <div className={stats.followerGrowth ? "followerChartContainer d-flex justify-content-center" : 'd-none'}>
                <div style={{ width: `${100 * 21/23}%`}}>
                <FollowerChart />
                </div>
            </div>
            </div>

            {/* Whole month for extra large screens */}
            <div className="d-none d-xl-block">
            <div className={stats.followerGrowth ? `followerChartContainer d-flex justify-content-${DateTime.utc() < day.endOf('month') ? 'end' : 'center'}` : 'd-none'}>
                <div style={{ width: `${100 * day.daysInMonth/(day.daysInMonth + (DateTime.utc() < day.endOf('month') ? 1 : 2))}%`}}>
                <FollowerChart />
                </div>
            </div>
            </div>
        </>
    )
}

export default FollowerChartHandler