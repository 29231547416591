import React from 'react'
import { useSelector } from 'react-redux'

const FollowerBreakdown = () => {
  const stats = useSelector(state => state.stats)

  const delta = stats && stats.delta ? stats.delta : null
  const gained = delta ? delta.followersGained : null
  const lost = delta ? delta.followersLost : null
  const gainedMessage = delta ? delta.gainedMessage : 'followers gained'
  const lostMessage = delta ? delta.lostMessage : 'followers lost'

  return (
    <>
      <p className="mt-3">{gainedMessage}</p>
      {!gained ? <></> : 
        <div className="row">
          {gained.map((follower) => {
            return (
              <div key={follower._id} className="col-8">
                <div className="card text-white bg-dark mb-3">
                  <h5 className="card-header">{follower.screenName}</h5>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li key="bio" className="list-group-item">Bio: {follower.description}</li>
                      <li key="followers" className="list-group-item">Followers: {follower.publicMetrics.followersCount}</li>
                      <li key="tweets" className="list-group-item">Tweets: {follower.publicMetrics.tweetCount}</li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      }
      <p className="mt-3">{lostMessage}</p>
      {!lost ? <></> : 
        <div className="row">
          {lost.map((follower) => {
            return (
              <div key ={follower._id} className="col-8">
                <div className="card text-white bg-dark mb-3">
                  <h5 className="card-header">{follower.screenName}</h5>
                  <div className="card-body">
                    <ul className="list-group list-group-flush">
                      <li key="bio" className="list-group-item">Bio: {follower.description}</li>
                      <li key="followers" className="list-group-item">Followers: {follower.publicMetrics.followersCount}</li>
                      <li key="tweets" className="list-group-item">Tweets: {follower.publicMetrics.tweetCount}</li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      }
    </>
  )
}

export default FollowerBreakdown