import { challengeDraftOptions } from '../options/challengeDraftOptions'
import { SET_DURATION, SET_MIN_TWEETS, SET_CREATOR, SET_STAKE_PER_TWEET, SET_INVITEE, INITIALIZE_DRAFT } from './types'

// To keep for custom challenge creation
export const setDuration = (startDate, endDate) => ({
  type: SET_DURATION,
  startDate,
  endDate
})

export const setMinTweets = (minTweets) => ({
  type: SET_MIN_TWEETS,
  minTweets
})

export const setCreator = (creator) => ({
  type: SET_CREATOR,
  creator
})

export const setStakePerTweet = (stakePerTweet) => ({
  type: SET_STAKE_PER_TWEET,
  stakePerTweet
})

export const setInvitee = (invitee) => ({
  type: SET_INVITEE,
  invitee
})

export const initializeDraft = (challengeDraft = challengeDraftOptions) => ({
  type: INITIALIZE_DRAFT,
  challengeDraft
})