import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import LoadingInline from './LoadingInline'
import { getFollowersByDay } from '../selectors/snapshots'

const FollowerTarget = ({twitterId, date, absoluteGain, percentGain, fixed}) => {
  const snapshots = useSelector(state => state.snapshots)
  const [followers, setFollowers] = useState(null)

  // Update followers
  useEffect(() => {
    const followers = getFollowersByDay(snapshots, twitterId, date)
    setFollowers(followers ? followers : null)
  }, [twitterId, date, snapshots])
  
  const calculateTarget = (followers, fixed, absoluteGain, percentGain) => {
    if (fixed === 'absolute') {
      return followers.length + Number(absoluteGain)
    }
    if (fixed === 'percent') {
      if (followers.length === 0 && percentGain >= 100) {
        return 1
      }
      else {
        return Math.round(followers.length * (1 + percentGain/100))
      }
    }
  }
  if (followers) {
    const target = calculateTarget(followers, fixed, absoluteGain, percentGain)
    return (
      <>
        <span>{target}</span>
        <span> </span>
        <span>
          ({followers.length} + {fixed === 'absolute' ? absoluteGain : `${percentGain}%`})
        </span>
      </>
    )
  } else {
    return (
      <LoadingInline/>
    )
  }

}

export default FollowerTarget