import axios from 'axios'
import { DateTime } from 'luxon'
import { LOAD_TWEETS } from './types'

const mapTweetDate = (tweets) => tweets.map((tweet) => {
  return {
    twitterId: tweet.twitterId,
    id: tweet.tweetId,
    text: tweet.text,
    createdAt: DateTime.fromISO(tweet.createdAt, { zone: 'utc'}),
    embedHTML: tweet.embedHTML
  }
})

// LOAD_TWEETS
export const loadTweets = (tweets) => ({
  type: LOAD_TWEETS,
  tweets
})

// GET_TWEETS_CURRENT
export const getTweetsCurrent = (startDate, endDate) => async (dispatch, getState) => {
  console.log('default state tweets', getState().tweets)
  const formattedStartDate = startDate.toFormat('yyyy-MM-dd')
  const formattedEndDate = endDate.toFormat('yyyy-MM-dd')
  const params = {
    startDate: formattedStartDate,
    endDate: formattedEndDate
  }
  const res = await axios.get('/tweets/load/current', { params })
  const tweets = mapTweetDate(res.data)
  console.log('current tweets', tweets)
  dispatch(loadTweets(tweets))
}

// GET_TWEETS_USER
export const getTweetsUser = () => async (dispatch, getState) => {
  const res = await axios.get('/tweets/load/user')
  const tweets = mapTweetDate(res.data)
  dispatch(loadTweets(tweets))
}

// GET_TWEETS
export const getTweets = () => async (dispatch, getState) => {
  const res = await axios.get('/tweets/load/all')
  const tweets = mapTweetDate(res.data)
  dispatch(loadTweets(tweets))
}

// REFRESH_TWEETS
export const refreshTweets = () => async (dispatch, getState) => {
    const state = getState()
    const date = state.day
    await axios.get(`/tweets/user/${date.toFormat('yyyy-MM')}`)
    dispatch(getTweets())
  }

export const refreshRecentTweets = () => async (dispatch, getState) => {
  await axios.get('/tweets/user/recent')
  dispatch(getTweets())
}