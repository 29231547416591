import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import Chart from 'react-apexcharts'

import { followerGrowthChartOptions } from '../options/followerGrowthChart'
import { updateFollowerCount, calculateForecastData } from '../selectors/stats'
import { getDateArray } from '../selectors/dates'

const FollowerChart = ({ followerGrowth = {...followerGrowthChartOptions}}) => {
  const [auth, day, stats] = useSelector(state => [state.auth, state.day, state.stats], shallowEqual)
  const [followerGrowthWeek, forecastDataWeek] = useSelector(state => [stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? updateFollowerCount(state.stats.followerGrowth[state.auth.twitterId][state.day.toFormat('yyyy-MM')], getDateArray(state.day, 7)) : followerGrowth, stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? calculateForecastData(getDateArray(state.day, 7)) : 0])
  const [followerGrowthFortnight, forecastDataFortnight] = useSelector(state => [stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? updateFollowerCount(state.stats.followerGrowth[state.auth.twitterId][state.day.toFormat('yyyy-MM')], getDateArray(state.day, 14)) : followerGrowth, stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? calculateForecastData(getDateArray(state.day, 14)) : 0])
  const [followerGrowthThreeWeeks, forecastDataThreeWeeks] = useSelector(state => [stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? updateFollowerCount(state.stats.followerGrowth[state.auth.twitterId][state.day.toFormat('yyyy-MM')], getDateArray(state.day, 21)) : followerGrowth, stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? calculateForecastData(getDateArray(state.day, 21)) : 0])
  const [followerGrowthMonth, forecastDataMonth] = useSelector(state => [stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? updateFollowerCount(state.stats.followerGrowth[state.auth.twitterId][state.day.toFormat('yyyy-MM')], getDateArray(state.day, state.day.daysInMonth)) : followerGrowth, stats.followerGrowth && stats.followerGrowth[auth.twitterId][day.toFormat('yyyy-MM')] ? calculateForecastData(getDateArray(state.day, state.day.daysInMonth)) : 0])
  
  return (
    <>
      {
        followerGrowthMonth ? (
          <>
            {/* Full week for mobile */}
            <div className="d-block d-md-none">
              <Chart 
                type = 'bar'
                options = {{...followerGrowthWeek.options, forecastDataPoints: {...followerGrowth.options.forecastDataPoints, count: forecastDataWeek}}}
                series = {followerGrowthWeek.series}
                height = {followerGrowthWeek.height}
                width = '100%'
              />
            </div>
            {/* Full fortnight for medium screens */}
            <div className="d-none d-md-block d-lg-none">
              <Chart 
                type = 'bar'
                options = {{...followerGrowthFortnight.options, forecastDataPoints: {...followerGrowth.options.forecastDataPoints, count: forecastDataFortnight}}}
                series = {followerGrowthFortnight.series}
                height = {followerGrowthFortnight.height}
                width = '100%'
              />
            </div>

            {/* Three weeks for large screens */}
            <div className="d-none d-lg-block d-xl-none">
              <Chart 
                type = 'bar'
                options = {{...followerGrowthThreeWeeks.options, forecastDataPoints: {...followerGrowth.options.forecastDataPoints, count: forecastDataThreeWeeks}}}
                series = {followerGrowthThreeWeeks.series}
                height = {followerGrowthThreeWeeks.height}
                width = '100%'
              />
            </div>

            {/* Whole month for extra large screens */}
            <div className="d-none d-xl-block">
              <Chart 
                type = 'bar'
                options = {{...followerGrowthMonth.options, forecastDataPoints: {...followerGrowth.options.forecastDataPoints, count: forecastDataMonth}}}
                series = {followerGrowthMonth.series}
                height = {followerGrowthMonth.height}
                width = '100%'
              />
            </div>
          </>
        ) : (
          <div style={{height: '100px'}}>
          </div>
        )
      }
    </>
  )
}

export default FollowerChart