import { DateTime } from 'luxon'

const datesReducerDefaultState = DateTime.utc().startOf('day')

const datesReducer = (state = datesReducerDefaultState, action) => {
  switch (action.type) {
    case 'SET_DAY':
      return action.day
    default:
      return state
  }
}

export default datesReducer