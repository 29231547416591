// get tweets for specified TimeFrame for one user
export const getUserTweetsWithDateMatch = (tweets, twitterId, day, dateMatchType = 'month') => {
  return tweets.filter((tweet) => {
    const twitterIdMatch = tweet.twitterId === twitterId
    const dateMatch = tweet.createdAt.hasSame(day, dateMatchType)
    return twitterIdMatch && dateMatch
  })
}

// get tweets for one month for each participant of a challenge

// get all time tweets for one user