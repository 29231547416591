import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { DateTime } from 'luxon'
import { getTweetGoal, getCurrentChallenge } from '../selectors/challenges'
import { getUserTweetsWithDateMatch } from '../selectors/tweets'

import FollowerChart from './FollowerChart'
import './ChallengeOverview.css'

const ChallengeOverview = () => {
  const [activityMode, setActivityMode] = useState(true)

  const toggleActivityMode = () => {
    setActivityMode(!activityMode)
  }
  const [auth, challenges, day, stats, tweets] = useSelector(state => [state.auth, state.challenges, state.day, state.stats, state.tweets], shallowEqual)
  const [currentChallenge, setCurrentChallenge] = useState(getCurrentChallenge(challenges, auth ? auth.twitterId : null, day))
  const [challengeTweets, setChallengeTweets] = useState(getUserTweetsWithDateMatch(tweets, auth.twitterId, day))
  const [tweetGoal, setTweetGoal] = useState(getTweetGoal(currentChallenge))

  // Update current challenge
  useEffect(() => {
    setCurrentChallenge(getCurrentChallenge(challenges, auth.twitterId, day))
  }, [auth, challenges, day])

  // Update challengeTweets
  useEffect(() => {
    setChallengeTweets(getUserTweetsWithDateMatch(tweets, auth.twitterId, day))
  }, [tweets, auth, day])

  // Update tweetGoal
  useEffect(() => {
    setTweetGoal(getTweetGoal(currentChallenge))
  }, [currentChallenge])

  return (
    <>
      <div className={activityMode ? "mb-4" : "mb-0"} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <p className="mb-0">{auth.screenName} {day.toFormat('MMMM')} {activityMode ? 'tweet activity' : 'follower growth'}</p>
        {
          currentChallenge ? (
            <button className="btn btn-outline-dark" onClick={toggleActivityMode}>
              {
                activityMode ? 'Follower Growth' : 'Tweet Activity'
              }
            </button>
          ) : (
            <></>
          )
        }
      </div>
      { 
        activityMode ? (
          <>
            <div className="progress mb-3">
              <div className="progress-bar progress-bar-striped" style={{width: `${challengeTweets.length / tweetGoal * 100}%`}} role="progressbar" aria-valuenow={challengeTweets.length} aria-valuemin="0" aria-valuemax={tweetGoal}>{challengeTweets.length}</div>
              <div id="incomplete" className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${(tweetGoal - challengeTweets.length) / tweetGoal * 100}%`}} aria-valuenow={tweetGoal - challengeTweets.length} aria-valuemin="0" aria-valuemax={tweetGoal}>{tweetGoal - challengeTweets.length}</div>
            </div>
          </>
        ) : (
          <>
            {/* Full week for mobile */}
            <div className="d-block d-md-none">
              <div className={stats.followerGrowth ? "followerChartContainer d-flex justify-content-center" : "d-none"}>
                <div style={{ width: `${100 * 7/9}%`}}>
                  <FollowerChart />
                </div>
              </div>
            </div>
            {/* Full fortnight for medium screens */}
            <div className="d-none d-md-block d-lg-none">
              <div className={stats.followerGrowth ? "followerChartContainer d-flex justify-content-center" : "d-none"}>
                <div style={{ width: `${100 * 14/16}%`}}>
                  <FollowerChart />
                </div>
              </div>
            </div>

            {/* Three weeks for large screens */}
            <div className="d-none d-lg-block d-xl-none">
              <div className={stats.followerGrowth ? "followerChartContainer d-flex justify-content-center" : 'd-none'}>
                <div style={{ width: `${100 * 21/23}%`}}>
                  <FollowerChart />
                </div>
              </div>
            </div>

            {/* Whole month for extra large screens */}
            <div className="d-none d-xl-block">
              <div className={stats.followerGrowth ? `followerChartContainer d-flex justify-content-${DateTime.utc() < day.endOf('month') ? 'end' : 'center'}` : 'd-none'}>
                <div style={{ width: `${100 * day.daysInMonth/(day.daysInMonth + (DateTime.utc() < day.endOf('month') ? 1 : 2))}%`}}>
                  <FollowerChart />
                </div>
              </div>
            </div>
          </>
      )}
    </>
  )
}

export default ChallengeOverview