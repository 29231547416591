import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setMinTweets } from '../actions/challengeDraft'

const MinTweetsInput = () => {
  const challengeDraft = useSelector(state => state.challengeDraft)
  const dispatch = useDispatch()

  const onMinTweetsChange = (e) => {
    e.preventDefault()
    const minTweets = e.target.value
    dispatch(setMinTweets(minTweets))
  }
  return (
    <div className="form-floating">
      <input
        className={`form-control text-center ${(challengeDraft.minTweets && challengeDraft.minTweets > 0) ? '' : 'is-invalid'}`}
        id="floatingInputOne"
        type="number"
        placeholder={challengeDraft.minTweets}
        value={challengeDraft.minTweets}
        onChange={onMinTweetsChange.bind(this)}
        min="1"
        step="1"
        required
      />
      <div id="floatingInputOneFeedback" className="invalid-feedback">Input must be greater than zero</div>
      <label className="form-control-placeholder" htmlFor="floatingInputOne">
        Tweets per day
      </label>
    </div>
  )
}

export default MinTweetsInput
