import React from 'react'
import DateInput from './DateInput'

const DateSelector = () => {
  return (
    <>
      <div className="row mb-3">
        <div className="col-12 col-md-4 p-3">
          <DateInput type="start"/>       
        </div>
        <div className="col-12 col-md-4 p-3">
          <DateInput type="end"/>
        </div>
      </div>
    </>
  )
}

export default DateSelector