import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { getUserTweetsWithDateMatch } from '../selectors/tweets'

import './ChallengeOverview.css'
import FollowerChartHandler from './FollowerChartHandler'

const ChallengeOverview = () => {
  const [activityMode, setActivityMode] = useState(true)

  const toggleActivityMode = () => {
    setActivityMode(!activityMode)
  }

  const checkBestDay = (day, challengeTweets) => {
    const [startDate, endDate] = [day.startOf('month'), day.endOf('month')]
    let compDate = startDate
    let maxTweets = 0
    while (compDate <= endDate) {
        const dayTweets = challengeTweets.filter((tweet) => tweet.createdAt.hasSame(compDate, 'day'))
        if (dayTweets.length > maxTweets) {
            maxTweets = dayTweets.length
        }
        compDate = compDate.plus({days: 1})
    }
    return maxTweets
  }

  const [auth, day, stats, tweets] = useSelector(state => [state.auth, state.day, state.stats, state.tweets], shallowEqual)
  console.log('tweets', tweets)
  const [challengeTweets, setChallengeTweets] = useState(getUserTweetsWithDateMatch(tweets ? tweets : [], auth.twitterId, day))
  const [tweetGoal, setTweetGoal] = useState(day.daysInMonth * checkBestDay(day, challengeTweets))

  console.log('day', day)
  // Update challengeTweets
  useEffect(() => {
    setChallengeTweets(getUserTweetsWithDateMatch(tweets, auth.twitterId, day))
  }, [tweets, auth, day])

  // Update tweetGoal
  useEffect(() => {
    setTweetGoal(day.daysInMonth * checkBestDay(day, challengeTweets))
  }, [day, challengeTweets])

  return (
    <>
      <div className={activityMode ? "mb-4" : "mb-0"} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <p className="mb-0">{auth.screenName} {day.toFormat('MMMM')} {activityMode ? 'tweet activity' : 'follower growth'}</p>
        {
            <button className="btn btn-outline-dark" onClick={toggleActivityMode}>
                {
                activityMode ? 'Follower Growth' : 'Tweet Activity'
                }
            </button>
        }
      </div>
      { 
        activityMode ? (
          <>
            <div className="progress mb-3">
              <div className="progress-bar progress-bar-striped" style={{width: `${challengeTweets.length / tweetGoal * 100}%`}} role="progressbar" aria-valuenow={challengeTweets.length} aria-valuemin="0" aria-valuemax={tweetGoal}>{challengeTweets.length}</div>
              <div id="incomplete" className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${(tweetGoal - challengeTweets.length) / tweetGoal * 100}%`}} aria-valuenow={tweetGoal - challengeTweets.length} aria-valuemin="0" aria-valuemax={tweetGoal}>{tweetGoal - challengeTweets.length}</div>
            </div>
          </>
        ) : (
            <FollowerChartHandler day={day} stats={stats}/>
      )}
    </>
  )
}

export default ChallengeOverview