import React, { useEffect } from 'react'
import FollowerProgressBar from './FollowerProgressBar'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { DateTime } from 'luxon'
import { getFollowerStats } from '../actions/stats'
import { getUserTweetsWithDateMatch } from '../selectors/tweets'
import { getTweetGoal } from '../selectors/challenges'

const ParticipantStats = ({participant, challenge}) => {
  const [auth, stats] = useSelector(state => [state.auth, state.stats], shallowEqual)
  const challengeTweets = useSelector(state => getUserTweetsWithDateMatch(state.tweets, participant.twitterId, challenge.startDate))
  const followers = useSelector(state => state.stats.followerGrowth && state.stats.followerGrowth[participant.twitterId] && state.stats.followerGrowth[participant.twitterId][challenge.startDate.toFormat('yyyy-MM')] ? state.stats.followerGrowth[participant.twitterId][challenge.startDate.toFormat('yyyy-MM')].seriesData : null)
  const tweetGoal = getTweetGoal(challenge)
  const absoluteGain = challenge.participants.find((p) => {
      return p.twitterId === participant.twitterId
    }).absoluteGain
  const followersStart = followers ? followers.find((item) => {
    return item.date.hasSame(challenge.startDate,'day')
  }).followerCount : 0
  const followersEnd = followers ? followers.find((item) => item.date.hasSame(DateTime.utc() < challenge.endDate ? DateTime.utc() : challenge.endDate,'day')).followerCount : 0
  const dispatch = useDispatch()
  useEffect(() => {
    if (auth && (!stats || !stats.followerGrowth || !stats.followerGrowth[participant.twitterId] || !stats.followerGrowth[participant.twitterId][challenge.startDate.toFormat('yyyy-MM')])) {
      const twitterId = participant.twitterId
      const startDate = challenge.startDate
      const endDate = challenge.endDate
      dispatch(getFollowerStats(twitterId, startDate, endDate))
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className="row">
        <div className="col-10 col-md-8">
          <p className="m-0">{participant.screenName}: {challengeTweets.length.toString()} tweets towards goal of {tweetGoal}</p>
          <div className="progress">
            <div className="progress-bar progress-bar-striped" style={{width: `${challengeTweets.length / tweetGoal * 100}%`}} role="progressbar" aria-valuenow={challengeTweets.length} aria-valuemin="0" aria-valuemax={tweetGoal}>{challengeTweets.length}</div>
            <div id="incomplete" className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${(tweetGoal - challengeTweets.length) / tweetGoal * 100}%`}} aria-valuenow={tweetGoal - challengeTweets.length} aria-valuemin="0" aria-valuemax={tweetGoal}>{tweetGoal - challengeTweets.length}</div>
          </div>
        </div>
        <div className="col-10 col-md-8">
          {
            followersEnd && absoluteGain ? (
              <>
                <FollowerProgressBar followersStart={followersStart} followersEnd={followersEnd} followersGain={followersEnd - followersStart} absoluteGain = {absoluteGain} />
              </>
            ) : (
              <>
              </>
            )
          }
        </div>
      </div>
    </>
  )
}

export default ParticipantStats