import React from 'react'
import MinTweetsInput from './MinTweetsInput'
import FollowerGainInput from './FollowerGainInput'

const ChallengeMetrics = () => {
  return (
    <div className="row">
        <div id="minTweet" className="col-12 col-sm-5 p-2">
          <MinTweetsInput />
        </div>
        <div id="followerGain" className="col-12 col-sm-7 p-2">
          <FollowerGainInput creator/>
        </div>
        {/* <div id="stakePerTweet" className="col-12 col-sm-6 p-2">
          <div className="form-floating">
            <input
              className="form-control text-center"
              id="FloatingInputThree"
              type="number"
              placeholder={this.props.challengeDraft.stakePerTweet/100}
              // value={this.props.challengeDraft.stakePerTweet/100}
              onChange={this.onStakePerTweetChange}
              min="1"
              step="1"
            />
            <label className="form-control-placeholder" htmlFor="FloatingInputThree">
              Stake Per Tweet
            </label>
          </div>
        </div> */}
      </div>
  )
}

export default ChallengeMetrics