import { DateTime } from 'luxon'

export const followerGrowthChartOptions = {
  options: {
    chart: {
      id: 'Follower Growth',
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true
          },
        },
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const selectedDate = config.w.config.xaxis.categories[config.dataPointIndex]
            const month = selectedDate.toFormat('MM')
            const day = selectedDate.toFormat('dd')
            window.location = `/statistics/followers/${selectedDate.year}/${month}/${day}`
        }
      }
    },
    // colors: [
    //   ({value, seriesIndex, w}) => {
    //     if (value > 133) {
    //       return '#FF0000'
    //     } else {
    //       return '#02DFDE'
    //     }
    //   }
    // ],
    grid: {
      show: false,
      padding: {
        left: 0,
        right: 0,
      }
    },
    xaxis: {
      labels: {
        show: false,
        datetimeUTC: true,
        formatter: (value, timestamp) => {
          return value && DateTime.isDateTime(value) ? value.toFormat('dd MMMM') : ''
        }
      },
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      },
      min: 1,
      categories: [],

    },
    yaxis: {
      show: false,
      min: 0
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '10px'
      }
    },
    forecastDataPoints: {
      count: 0,
      fillOpacity: 0.2,
      dashArray: 4
    },
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: () => ''
        }
      }
    }
  },
  series: [
  ],
  responsive: [
    {
      breakpoint: 576
    },
    {
      breakpoint: 768
    },
    {
      breakpoint: 992
    },
    {
      breakpoint: 1200
    },
    {
      breakpoint: 1400
    }
  ],
  height: 150
}