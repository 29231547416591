// get user from twitterId
export const getUserFromTwitterId = (users, twitterId) => (users.find((user) => {
  return (user.twitterId === twitterId)
}))

// get user from screenName
export const getUserFromScreenName = (users, screenName) => (users.find((user) => (user.screenName === screenName)))

// return array of participants in a challenge
export const getParticipants = (challenge, users) => {
  if (challenge && challenge.participants && users.length > 0) {
    return challenge.participants.map((participant) => {
      const user = getUserFromTwitterId(users, participant.twitterId)
      participant.screenName = user.screenName
      return participant
    })

  }
}

// return array of invitees in a challenge
export const getInvitees = (challenge, users) => {
  if (challenge && challenge.invites && users.length > 0) {
    return challenge.invites.map((invite) => {
      let invitee = getUserFromTwitterId(users, invite.invitee)
      invitee.invitedBy = invite.invitedBy
      invitee.accepted = invite.accepted
      return invitee
    })
  }
}

// return array of challenges that user 
export const getPendingInvites = (challenges, twitterId) => {
  const pendingInvites = challenges.filter((challenge) => challenge.invites.some((invite) => invite.invitee === twitterId && invite.accepted === undefined))
  return pendingInvites
}

// return array of challenges user was invited to
export const getInvites = (challenges, twitterId) => {
  if (challenges) {
    const invites = challenges.filter((challenge) => challenge.invites.some((invite) => invite.invitee === twitterId))
    return invites
  } else {
    return []
  }
}

// get challenges user participated in
export const getUserChallenges = (challenges, twitterId) => {
  if (twitterId) {
    const userChallenges = challenges.filter((challenge) => {
      if (challenge.participants.map(participant => participant.twitterId).includes(twitterId)) {
        return true
      } else {
        return false
      }
    })
    return userChallenges
  }
}