import axios from 'axios'
import { LOAD_USERS } from './types'
import { getSnapshots } from './snapshots'

// LOAD_USERS
export const loadUsers = (users) => ({
  type: LOAD_USERS,
  users
})

// GET_USERS
export const getUsers = () => async (dispatch, getState) => {
  const res = await axios.get('/api/load/users')
  const users = res.data
  dispatch(loadUsers(users.map((user) => {
    return {
      twitterId: user.twitterId,
      screenName: user.screenName
    }
  })))
}

// GET_FOLLOWERS
export const getFollowers = () => async (dispatch, getState) => {
  await axios.get('/api/followers')
  dispatch(getSnapshots())
}