export const particleOptions = {
  "background": {
    "color": {
      "value": "#ffffff"
    },
  },
  "fpsLimit": 60,
  "interactivity": {
    "modes": {
      "attract": {
        "maxSpeed": 1
      },
      "bubble": {
        "distance": 400,
        "duration": 2,
        "opacity": 0.8,
        "size": 40
      },
      "grab": {
        "distance": 400
      },
      "repulse": {
        "maxSpeed": 1
      },
      "slow": {
        "radius": 100
      }
    },
    "detectsOn": "canvas",
    "events": {
      "onHover": {
        "enable": false,
        "mode": "slow"
      }
    }
  },
  "particles": {
    "color": {
      "value": "#AB7C94"
    },
    "links": {
      "color": {
        "value": "#AB7C94"
      },
      "distance": 150,
      "enable": true,
      "opacity": 0.5
    },
    "move": {
      "attract": {
        "rotate": {
          "x": 600,
          "y": 1200
        }
      },
      "enable": true,
      "gravity": {
        "maxSpeed": 5
      },
      "path": {},
      "outModes": {
        "bottom": "out",
        "left": "out",
        "right": "out",
        "top": "out"
      },
      "speed": 1,
      "spin": {}
    },
    "number": {
      "density": {
        "enable": true
      },
      "value": 80
    },
    "opacity": {
      "value": 0.5,
      "animation": {
        "speed": 10,
        "minimumValue": 0.1
      }
    },
    "size": {
      "random": {
        "enable": true
      },
      "value": {
        "min": 1,
        "max": 5
      },
      "animation": {
        "speed": 10,
        "minimumValue": 0.1
      }
    }
  }
}