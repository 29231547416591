import { followerGrowthChartOptions } from '../options/followerGrowthChart'
const { DateTime, Interval } = require('luxon')

// calculate for how many days of the month TweepWars needs to forecast follower growth
export const calculateForecastData = (dateArray) => {
  const endDate = dateArray[dateArray.length - 1]

  const daysTillMonthEnd = Interval.fromDateTimes(DateTime.utc(), endDate).length('days') + 1
  let forecastData = 0
  if (daysTillMonthEnd) {
    forecastData = Math.round(daysTillMonthEnd)
  }
  return forecastData
}

// generate followerGrowthChartOptions based on displayed timeframe
export const updateFollowerCount = (newState, dateArray) => {
  const categoryData = newState.categoryData
  const seriesData = newState.seriesData
  const dayArray = dateArray.map(date => date.day)

  const trimmedCategoryData = categoryData.filter((date) => {
    return dayArray.includes(date.day)
  })

  const trimmedSeriesData = seriesData.filter((item) => {
    return dayArray.includes(item.date.day)
  }).map(item => item.followerCount)

  const growthChartOptions = {
    ...followerGrowthChartOptions,
    options: {
      ...followerGrowthChartOptions.options,
      xaxis: {
        ...followerGrowthChartOptions.options.xaxis,
        categories: trimmedCategoryData
      },
      yaxis: {
        ...followerGrowthChartOptions.options.yaxis,
        max: Math.round(Math.max(...trimmedSeriesData) * 1.3)
      }
    },
    series: [
      {
        name: 'follower',
        data: trimmedSeriesData
      }
    ]
  }
  return growthChartOptions
  }