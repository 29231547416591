import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import authReducer from '../reducers/authReducer'
import tweetsReducer from '../reducers/tweetsReducer'
import datesReducer from '../reducers/datesReducer'
import challengesReducer from '../reducers/challengesReducer'
import challengeDraftReducer from '../reducers/challengeDraftReducer'
import userReducer from '../reducers/userReducer'
import snapshotsReducer from '../reducers/snapshotsReducer'
import statsReducer from '../reducers/statsReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      users: userReducer,
      tweets: tweetsReducer,
      day: datesReducer,
      challenges: challengesReducer,
      challengeDraft: challengeDraftReducer,
      snapshots: snapshotsReducer,
      stats: statsReducer
    }),
    composeEnhancers(applyMiddleware(thunk))
  )
  return store
}

export default configureStore