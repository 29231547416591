import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import logo from '../images/eagle.svg'
import './Header.css'

const Header = () => {
  const [show, setShow] = useState(true)
  
  const location = useLocation()

  const determineConnectTwitter = (pathname) => {
    return pathname === '/' ? false : true
  }

  const determineBreadcrumb = (pathname) => {
    if (pathname.match(/\/statistics\/followers\/\d{4}\/\d{2}\/\d{2}/)) {
      return {
        layerOne: 'Statistics',
        layerTwo: 'Follower Development'
      }
    } else if (pathname.match(/\/statistics\/\d{4}\/\d{2}\/\d{2}/)) {
      return {
        layerOne: 'Statistics',
        layerTwo: 'Monthly Overview'
      }
    } else if (pathname.match(/\/challenge/)) {
      return {
        layerOne: 'Challenges'
      }
    } else if (pathname.match(/\/settings/)) {
      return {
        layerOne: 'Settings'
      }
    } else {
      return {}
    }
  }

  const breadcrumb = determineBreadcrumb(location.pathname)
  const showConnectTwitter = determineConnectTwitter(location.pathname)

  const controlNavbar = () => {
    if (window.scrollY > 80) {
      setShow(false)
    } else {
      setShow(true)
    }
  }

  const day = useSelector((state) => state.day)
  const auth = useSelector((state) => state.auth)
  const yyyy = day.year.toString()
  const MM = day.month.toString().padStart(2, '0')
  const dd = day.day.toString().padStart(2, '0')

  useEffect(() => {
    window.addEventListener('scroll', controlNavbar)
    return () => {
      window.removeEventListener('scroll', controlNavbar)
    }
  }, [])


  // display nav-items depending on login status
  const renderNavContent = () => {
    switch (auth) {
      case null:
        return
      case false:
        if (showConnectTwitter) {
          return [
            <li className="nav-item" key='1'><a className="nav-link" href="/auth/twitter">Connect Twitter</a></li>
          ]
        } else {
          return
        }
      default:
        switch (location.pathname) {
          case '/':
            return [
              <li className="nav-item" key='challenge'><Link className="nav-link" to="/challenges">Challenges</Link></li>,
              <li className="nav-item" key='statistics'><Link className="nav-link" to={`/statistics/${day.toFormat('yyyy/MM/dd')}`}>Statistics</Link></li>,
              <li className="nav-item" key='logout'><a className="nav-link" href="/api/logout">Logout</a></li>
            ]
          case `/day/${yyyy}/${MM}/${dd}`:
            return [
              <li className="nav-item" key='challenge'><Link className="nav-link" to="/challenges">Challenges</Link></li>,
              <li className="nav-item" key='statistics'><Link className="nav-link" to={`/statistics/${day.toFormat('yyyy/MM/dd')}`}>Statistics</Link></li>,
              <li className="nav-item nav-link d-none d-xl-block" key="expand" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Menu</li>,
              <li className="nav-item" key='logout'><a className="nav-link" href="/api/logout">Logout</a></li>
            ]
          default:
            return [
              <li className="nav-item" key='challenge'><Link className="nav-link" to="/challenges">Challenges</Link></li>,
              <li className="nav-item" key='statistics'><Link className="nav-link" to={`/statistics/${day.toFormat('yyyy/MM/dd')}`}>Statistics</Link></li>,
              // <li className="nav-item" key='donate'><Link className="nav-link" to="/donate">Donate</Link></li>,
              <li className="nav-item nav-link d-none d-xl-block" key="expand" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasScrolling" aria-controls="offcanvasScrolling">Menu</li>,
              <li className="nav-item" key='logout'><a className="nav-link" href="/api/logout">Logout</a></li>
            ]
        }

    }
  }
  return (
    <nav id="navbar" className="navbar navbar-expand-md navbar-light fixed-top">
      <div className="container">
          <div className="row">
            <div style={{visibility: show ? 'visible' : 'hidden'}} className="col">
              <Link 
                id="banner"
                to={auth ? `/statistics/${yyyy}/${MM}/${dd}` : '/'}
                className="navbar-brand"
              >            
                <img id="logo" className="img-fluid me-2" src={logo} alt="TweepWars Logo"/>
                <span>TweepWars</span>
              </Link>
              <nav className="breadcrumbNav" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  {
                    location.pathname === '/' ? (
                      <></>
                    ) : (
                      <li className="breadcrumb-item"><Link className="link-secondary" to={`/`}>Home</Link></li>
                    )
                  }
                  {
                    breadcrumb.layerOne ? (
                      <li className={`breadcrumb-item ${breadcrumb.layerTwo ? '' : 'active'}`}>
                        {
                          breadcrumb.layerTwo === 'Follower Development' ? (
                            <Link 
                              className="link-secondary" 
                              to={breadcrumb.layerOne === 'Statistics' ? `/statistics/${yyyy}/${MM}/${dd}` : `/${breadcrumb.layerOne.toLowerCase()}`}
                            >
                              {breadcrumb.layerOne}
                            </Link>
                          ) : (
                            breadcrumb.layerOne
                          )
                        }
                      </li>
                    ) : (<></>)
                  }
                  { 
                    breadcrumb.layerTwo ? (
                      <li className={`breadcrumb-item ${breadcrumb.layerThree ? '' : 'active'}`} aria-current="page">{breadcrumb.layerTwo}</li>
                    ) : (<></>)
                  }
                </ol>
              </nav>
            </div>
          </div>
          <button 
            className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent" 
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div style={{visibility: show ? 'visible' : 'hidden'}} className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul id="nav-mobile-right" className="navbar-nav ms-auto mb-2 float-end">
              {renderNavContent()}
            </ul>
          </div>
      </div>
    </nav>
  )
}

export default Header