import axios from 'axios'

import { CREATE_CONNECT_ACCOUNT, GET_ACCOUNT_STATUS } from './types'

export const createConnectAccount = () => async (dispatch) => {
  const res = await axios.post('/api/create-connect-account')
  dispatch({ type: CREATE_CONNECT_ACCOUNT, payload: res.data })
  return res
}

export const getAccountStatus = () => async (dispatch) => {
  const res = await axios.post('/api/get-account-status')
  dispatch({ type: GET_ACCOUNT_STATUS, payload: res.data })
  return res
}

export const initiateDonation = async (productId) => {
  const res = await axios.post('/api/create-donation-session', {productId})
  return res.data
}