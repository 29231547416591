// challengeDraft
export const SET_DURATION = 'SET_DURATION'
export const SET_MIN_TWEETS = 'SET_MIN_TWEETS'
export const SET_CREATOR = 'SET_CREATOR'
export const SET_STAKE_PER_TWEET = 'SET_STAKE_PER_TWEET'
export const SET_INVITEE = 'SET_INVITEE'
export const INITIALIZE_DRAFT = 'INITIALIZE_DRAFT'

// challenges
export const LOAD_CHALLENGES = 'LOAD_CHALLENGES'
export const CREATE_CHALLENGE = 'CREATE_CHALLENGE'
export const UPDATE_CHALLENGE = 'UPDATE_CHALLENGE'

// dates
export const SET_DAY = 'SET_DAY'

// snapshots
export const LOAD_SNAPSHOTS = 'LOAD_SNAPSHOTS'

// stats
export const LOAD_STATS = 'LOAD_STATS'

// stripe
export const HANDLE_TOKEN = 'HANDLE_TOKEN'
export const CREATE_CONNECT_ACCOUNT = 'CREATE_CONNECT_ACCOUNT'
export const GET_ACCOUNT_STATUS = 'GET_ACCOUNT_STATUS'

// tweets
export const LOAD_TWEETS = 'LOAD_TWEETS'

// users
export const LOAD_USERS = 'LOAD_USERS'
export const GET_USER_SUCCESS  = 'GET_USER_SUCCESS'
export const GET_USER_START = 'GET_USER_START'
