import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { getUser } from '../actions/index'
import Loading from './LoadingPage'


const PrivateRoute = ({path, component}) => {
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()
  useEffect(() => {
    if (auth === 'ready') {
      dispatch(getUser())
    }
  }, [dispatch, auth])
  if (auth) {
    if (auth === 'ready') {
      return <></>
    }
    if (auth === 'pending') {
      return <Loading/>
    }
    return (
      <Route
        path={path}
        component={component}
      />
    )
  } else {
    return (
      <Redirect to={{pathname: "/unauthorized", state: { path }}} />
    )
  }
}

export default PrivateRoute