import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
  const auth = useSelector(state => state.auth)
  const renderContent = () => {
    switch (auth) {
      case null:
        return
      case false:
        return [
          <li className="nav-item" key='Terms of Use'><Link className="nav-link" to="/terms-and-conditions">Terms of Use</Link></li>
        ]
      default:
        return [
          // <li className="nav-item" key='Feature Requests'><Link className="nav-link" to="/feature-requests">Feature Requests</Link></li>,
          <li className="nav-item" key='Terms of Use'><Link className="nav-link" to="/terms-and-conditions">Terms of Use</Link></li>
        ]
    }
  }
  return (
    <nav id="footer" className="navbar navbar-light navbar-expand fixed-bottom">
      <div className="container">
        <div className="d-none">Icon made by <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">smalllikeart</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        <ul className="navbar-nav ms-auto">
          {renderContent()}
        </ul>
      </div>
    </nav>
  )
}

export default Footer