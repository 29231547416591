import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import ChallengeDraftForm from './ChallengeDraftForm'
import Challenge from './Challenge'
import ChallengeInviteForm from './ChallengeInviteForm'
import Invite from './Invite'
import { getUsers } from '../actions/users'
import { getRecentSnapshots } from '../actions/snapshots'
import { signedUpNextMonth } from '../selectors/challenges'
import { getPendingInvites, getUserChallenges } from '../selectors/users'
import { getChallenges } from '../actions/challenges'

const ChallengeDashboard = () => {
  const [auth, challenges, snapshots, users] = useSelector((state) => [state.auth, state.challenges, state.snapshots, state.users], shallowEqual)
  const [userChallenges] = useState(getUserChallenges(challenges, auth.twitterId))
  const [snapshotsCollected] = useState(snapshots.length > 0)
  const [nextMonthChallenge, setNextMonthChallenge] = useState(signedUpNextMonth(challenges, auth ? auth.twitterId : null))
  const [pendingInvites, setPendingInvites] = useState(getPendingInvites(challenges, auth ? auth.twitterId : null))
  const dispatch = useDispatch()

  const checkIfAllParticipantsCovered = (nextMonthChallenge, snapshots) => {
    return nextMonthChallenge.participants.every((participant) => snapshots.some((snapshot) => participant.twitterId === snapshot.twitterId && DateTime.utc().hasSame(snapshot.timestamp, 'day')))
  }

  // Get users from database
  useEffect(() => {
    if (users.length === 0) {
      dispatch(getUsers())
    }
  }, [users, dispatch])

  // Get challenges from database
  useEffect(() => {
    if (userChallenges.length === 0) {
      dispatch(getChallenges())
    }
  }, [userChallenges, dispatch])

  // Get snapshots from database
  useEffect(() => {
    if (auth && auth !== 'ready' && auth !== 'pending' && nextMonthChallenge) {
      if (!snapshotsCollected || !checkIfAllParticipantsCovered(nextMonthChallenge, snapshots)) {
        dispatch(getRecentSnapshots())
      }
    }
    // eslint-disable-next-line
  }, [snapshotsCollected, nextMonthChallenge, auth, dispatch])

  // Update nextMonthChallenge
  useEffect(() => {
    if (auth) {
      setNextMonthChallenge(signedUpNextMonth(challenges, auth.twitterId))
    }
  }, [auth, challenges])

  // Update pendingInvites
  useEffect(() => {
    if (auth) {
      setPendingInvites(getPendingInvites(challenges, auth.twitterId))
    }
  }, [auth, challenges])
  
  return (
    <div>
      <h6>
        Upcoming Challenge
      </h6>
      {
        nextMonthChallenge ? (
          <>
            <Challenge challenge={nextMonthChallenge} key='nextMonthChallenge'/>
            <ChallengeInviteForm nextMonthChallenge={nextMonthChallenge}/>
          </>
        ) : (
          <>
            {
              pendingInvites.length > 0 ? (
                <>
                  Join your friends in next month' challenge
                  {pendingInvites.map((challenge) => {
                    return (
                      <div className="my-3" key={challenge.id}>
                        <Invite challenge={challenge}/>
                      </div>
                    )
                  })}
                </>
              ) : (
                <></>
              )
            }
            <ChallengeDraftForm />
          </>
        )
      }
      <h6 className="mt-3">Current Challenge</h6>
      {
        userChallenges && userChallenges.some((challenge) => (challenge.startDate < DateTime.utc() && challenge.endDate > DateTime.utc())) ? (
          <>
            <Challenge challenge={userChallenges.find((challenge) => challenge.startDate < DateTime.utc() && challenge.endDate > DateTime.utc())} key={'current Challenge'}/>
          </>
        ) : (
          <>
            <p>You have not entered a challenge for the current month</p>
          </>
        )
      }
      <h6 className="mt-3">Your past challenges</h6>
      {
        userChallenges ? 
          userChallenges.filter((challenge) => challenge.startDate < DateTime.utc().plus({ month: -1 }).endOf('month')).length === 0 ? (
            <>
              <p>No challenges on record</p>
            </>
          ) : (
            userChallenges.filter((challenge) => challenge.startDate < DateTime.utc().plus({ month: -1 }).endOf('month')).map((challenge) => {
              return (
                <Challenge challenge={challenge} key={challenge.id.toString()}/>
              )
            })
          ) : (
            <>
            </>
          )
      }
    </div>
  )
}

export default ChallengeDashboard