import { DateTime } from 'luxon'


// check if User is signed up for next month' challenge
export const signedUpNextMonth = (challenges, twitterId) => {
  const date = DateTime.utc().plus({ month: 1 })
  if (!!twitterId) {
    const nextMonthChallenge = challenges.find((challenge) => {
      if (challenge.participants.map(participant => participant.twitterId).includes(twitterId) && challenge.startDate < date && challenge.endDate > date) {
        return true
      } else {
        return false
      }
    })
    return nextMonthChallenge
  }
}

export const getCurrentChallenge = (challenges, twitterId, day) => {
  if (twitterId) {
    const currentChallenge = challenges.find((challenge) => {
      return challenge.participants.map(participant => participant.twitterId).includes(twitterId) && challenge.startDate <= day && challenge.endDate >= day
    })
    return currentChallenge
  }
}

// calculate tweetsToWin
export const getTweetGoal = (challenge) => {
  if (challenge && challenge.minTweets) {
    const tweetGoal = challenge.minTweets * challenge.startDate.daysInMonth
    return tweetGoal   
  }
  return undefined
}

export const getTotalStake = (challenge) => {
  if (challenge & challenge.stakePerTweet) {
    return challenge.stakePerTweet/100 * challenge.minTweets * challenge.startDate.daysInMonth
  }
  return undefined
}