import React from 'react';
import loader from './Spinner200px.gif'

const LoadingPage = () => (
  <>
    <div className="loader d-flex justify-content-center">
      <img alt="loading" className="loader__image" src={loader} />
    </div>
  </>
);

export default LoadingPage;