import React, { useEffect, useState } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { acceptInvite, declineInvite } from '../actions/challenges'
import { getUserFromTwitterId } from '../selectors/users'

const Invite = ({challenge}) => {
  const dispatch = useDispatch()
  const [auth, users] = useSelector(state => [state.auth, state.users], shallowEqual)
  const [invitedBy, setInvitedBy] = useState(getUserFromTwitterId(users, challenge.invites.find((invite) => auth.twitterId === invite.invitee)).invitedBy)

  // Update invited by
  useEffect(() => {
    setInvitedBy(getUserFromTwitterId(users, challenge.invites.find((invite) => auth.twitterId === invite.invitee)).invitedBy)
  }, [users, auth, challenge])

  const triggerAcceptInvite = () => {
    dispatch(acceptInvite(challenge, auth.twitterId))
  }

  const triggerDeclineInvite = () => {
    dispatch(declineInvite(challenge, auth.twitterId))
  }

  return (
    <div>
      {
        !invitedBy ? (
          <></>
        ) : (
          <>
            {challenge.minTweets} {challenge.minTweets < 2 ? 'tweet' : 'tweets'} per day
            invited by {invitedBy.screenName}
            <button className="btn btn-outline-dark mx-3" onClick={triggerAcceptInvite}>Accept</button>
            <button className="btn btn-dark" onClick={triggerDeclineInvite}>Decline</button>
          </>
        )
      }
    </div>
  )
}

export default Invite