const userReducerDefaultState = []

const userReducer = (state = userReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOAD_USERS':
      return [
        ...action.users
      ]
    default:
      return state
  }
}

export default userReducer