const challengesReducerDefaultState = []

const challengesReducer = (state = challengesReducerDefaultState, action) => {
  switch (action.type) {
    case 'LOAD_CHALLENGES':
      return [...action.challenges]
    case 'CREATE_CHALLENGE':
      return [
        ...state,
        action.challenge
      ]
    case 'UPDATE_CHALLENGE':
      return state.map((challenge) => {
        if (challenge.id === action.challenge.id) {
          return action.challenge
        } else {
        return challenge
        }
      })
    default:
      return state
  }
}

export default challengesReducer