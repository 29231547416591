import { DateTime } from 'luxon'

export const challengeDraftOptions = {
    startDate: DateTime.utc().plus({ month: 1}).startOf('month'),
    endDate: DateTime.utc().plus({ month: 1 }).endOf('month'),
    timeZone: 'UTC',
    minTweets: 5,
    stakePerTweet: 100,
    creator: {
      absoluteGain: 100,
      percentGain: 25,
      fixed: 'percent'
    },
    // absoluteGain: 100,
    // percentGain: 25,
    // fixed: 'percent',
    invitee: {
      twitterId: '',
      screenName: '',
      absoluteGain: 100,
      percentGain: 25,
      fixed: 'percent'
    }
    // inviteAbsoluteGain: 100,
    // invitePercentGain: 25,
    // inviteFixed: 'percent'
  }