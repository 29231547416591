import { GET_USER_START, GET_USER_SUCCESS } from '../actions/types'

const authReducer = (state = 'ready', action) => {
  switch (action.type) {
    case GET_USER_SUCCESS:
      return action.payload || false
    case GET_USER_START:
      return 'pending'
    default:
      return state
  }
}

export default authReducer