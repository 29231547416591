const NotInvited = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>
        Signup Failed
      </h1>
      <p>TweepWars is currently invite only.</p>
      <p>If you know somebody who is already using TweepWars, you can request an invite code from them, or message me on Twitter <a className="link-dark" href="https://www.twitter.com/gibital">@gibital</a></p>
    </div>
  )
}

export default NotInvited