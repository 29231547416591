import React, { useState, useEffect } from 'react'
import { DateTime } from 'luxon'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Tweet } from 'react-twitter-widgets'
import { getFollowers } from '../actions/users'
import { getUserSnapshotByDay } from '../actions/snapshots'
import { getUserTweetsWithDateMatch } from '../selectors/tweets'
import { getFollowersByDay, getPreviousSnapshot } from '../selectors/snapshots'

const DayDashboard = ({day = DateTime.utc().startOf('day'), detailView = true}) => {
  const [auth, tweets, snapshots] = useSelector(state => [state.auth, state.tweets, state.snapshots], shallowEqual)
  const [followers, setFollowers] = useState(undefined)
  const [followerGain, setFollowerGain] = useState(null)
  const [todaysTweets, setTodaysTweets] = useState(getUserTweetsWithDateMatch(tweets, auth.twitterId, day, 'day'))
  const [gettingFollowers, setGettingFollowers] = useState(false)
  const dispatch = useDispatch()

  // Update followers
  useEffect(() => {
    if (auth) {
      const followers = getFollowersByDay(snapshots, auth.twitterId, day)
      setFollowers(followers)
    }
  }, [auth, snapshots, day])

  // Update followerGain
  useEffect(() => {
    const previousDay = day.minus({days: -1}).endOf('day')
    const previousSnapshot = getPreviousSnapshot(snapshots, auth.twitterId, previousDay)
    const followers = getFollowersByDay(snapshots, auth.twitterId, day)
    if (followers && previousSnapshot) {
      const previousFollowers = previousSnapshot.followers
      setFollowerGain(followers.length - previousFollowers.length)
    } else {
      setFollowerGain(null)
    }
  }, [auth, day, snapshots])

  // Update todaysTweets
  useEffect(() => {
    setTodaysTweets(getUserTweetsWithDateMatch(tweets, auth.twitterId, day, 'day'))
  }, [tweets, auth, day])

  // look up snapshot from Database
  useEffect(() => {
      dispatch(getUserSnapshotByDay(auth.twitterId, day))
  }, [auth, day, snapshots, dispatch])

  useEffect(() => {
    if (followers) {
      setGettingFollowers(false)
    }
  }, [followers, snapshots])

  const triggerGetFollowers = () => {
    setGettingFollowers(true)
    try {
      dispatch(getFollowers())
    } catch (err) {
      console.log('triggerGetFollowers in DayDashboard', err)
      setGettingFollowers(false)
    }
  }
  return (
    <div>
      {
        !followers ? (
          <>
            <p>No follower statistics available for {auth.screenName} on {day.toFormat('MMMM dd, yyyy')}</p>
            { 
              day.hasSame(DateTime.utc(),'day') ? (
                <button
                  className="btn btn-dark"
                  disabled={gettingFollowers}
                  onClick={triggerGetFollowers}
                >
                  {gettingFollowers ? 'Processing' : 'Get followers'}
                </button>
              ) : (
                <></>
              )
            }
          </>
        ) : (
          followers.length === 0 ? (
            <p>{auth.screenName} did not have any followers on {day.toFormat('MMMM dd, yyyy')}</p>
          ) : (
            <p>{auth.screenName} {day.endOf('day') < DateTime.utc() ? ('had') : ('has')} {followers.length} followers {day.endOf('day') < DateTime.utc() ? ('on') : ('as of')} {day.toFormat('MMMM dd, yyyy')} <span className={!followerGain ? 'd-none': 'd-inline-block' }>({followerGain >= 0 ? `+${followerGain}` : followerGain })</span></p>
          )
        )
      }
      {
        todaysTweets.length === 0 ? (
          <p>No tweets</p>
        ) : (
          todaysTweets.map((tweet) => (
            <Tweet key={tweet.id} tweetId={tweet.id} options={{ dnt:"true", theme:"dark", width:"auto", conversation: detailView ? "all" : "none" }}/>
        ))
      )}
    </div>
  )
}

export default DayDashboard