import React from 'react'
import DonationNonRecurring from './Checkout'

const FeatureRequests = () => {
  return (
    <div>
      <h3>Feature Requests</h3>
      <ul>
        <li>Gamification</li>
        <li>Analytics</li>
        <li>Implement wagers</li>
        <li>Write in option</li>
      </ul>
      <DonationNonRecurring />
    </div>
  )
}

export default FeatureRequests