import React from 'react'
import { useSelector } from 'react-redux'
import OffcanvasPrivate from './OffcanvasPrivate'
import OffcanvasPublic from './OffcanvasPublic'

const Offcanvas = () => {
  const auth = useSelector(state => state.auth)
  
  if (!auth) {
    return <OffcanvasPublic/>
  } else {
    if (auth === 'ready') {
      return <></>
    }
    if (auth === 'pending') {
      return <></>
    }
    return <OffcanvasPrivate/>
  }
}


export default Offcanvas